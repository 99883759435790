import { useCallback } from "react";
import {
  doc,
  query,
  limit,
  where,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import moment from "moment";

import db from "../db/firebase";
import { Calf } from "../models";
import { useAuth } from "../Auth/AuthProvider";
import { getCurrentTimestamp } from "../utils";
import useBooksService from "./useBooksService";
import { calfConverter } from "../db/converters";
import useVaccinesService from "./useVaccinesService";

export default function useCalvesService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;
  const { getVaccines } = useVaccinesService();
  const { updateCalvesCount } = useBooksService();

  const getCalf = useCallback(
    async (calfId: string): Promise<Calf | undefined> => {
      const calfDocRef = doc(
        db,
        `organizations/${orgId}/calves`,
        calfId
      ).withConverter(calfConverter);

      const calfDoc = await getDoc(calfDocRef);
      return calfDoc.data();
    },
    [orgId]
  );

  const getCalfSub = useCallback(
    (calfId: string, cb: (calf: Calf | undefined) => void): void => {
      const calfDocRef = doc(
        db,
        `organizations/${orgId}/calves`,
        calfId
      ).withConverter(calfConverter);
      onSnapshot(calfDocRef, (snapshot) => {
        cb(snapshot.data());
      });
    },
    [orgId]
  );

  const getFemaleCalvesByBook = useCallback(
    async (bookId: string): Promise<Calf[]> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("gender", "==", "Heifer"),
        where("bookId", "==", bookId)
      ).withConverter(calfConverter);

      const calvesDocs = await getDocs(calvesCollectionRef);
      return calvesDocs.docs.map((doc) => doc.data());
    },
    [orgId]
  );

  const getCalfByNameAndBook = useCallback(
    async (calfName: string, bookId: string): Promise<Calf | undefined> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("calfName", "==", calfName),
        where("bookId", "==", bookId),
        limit(1)
      ).withConverter(calfConverter);
      const calfDoc = await getDocs(calvesCollectionRef);
      if (calfDoc.size > 0) {
        return calfDoc.docs[0].data();
      }
    },
    [orgId]
  );

  const addCalf = useCallback(
    (calfId: string, calf: Calf): void => {
      setDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          ...calf,
          status: "active",
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateCalf = useCallback(
    async (calfId: string, calf: Partial<Calf>): Promise<void> => {
      const currentCalf = (await getCalf(calfId))!;

      // if (!currentCalf.isDead && calf.isDead) {
      //   updateCalvesCount(calf.bookId!, -1);
      // }

      updateDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          ...calf,
          updatedAt: getCurrentTimestamp(),
        }
      );

      if (calf.bookId && currentCalf.bookId !== calf.bookId) {
        updateCalvesCount(calf.bookId!);
        updateCalvesCount(currentCalf.bookId);
      }
    },
    [orgId, getCalf, updateCalvesCount]
  );

  const deleteCalf = useCallback(
    async (calfId: string) => {
      const calf = (await getCalf(calfId))!;

      updateDoc(
        doc(db, `organizations/${orgId}/calves`, calfId).withConverter(
          calfConverter
        ),
        {
          status: "deleted",
          updatedAt: getCurrentTimestamp(),
        }
      );

      updateCalvesCount(calf.bookId);
    },
    [orgId, getCalf, updateCalvesCount]
  );

  const updateCalvesCowName = useCallback(
    async (newCowName: string, originalCowName: string): Promise<void> => {
      const calvesCollectionRef = query(
        collection(db, `organizations/${orgId}/calves`),
        where("cowName", "==", originalCowName)
      ).withConverter(calfConverter);
      const calvesDocs = await getDocs(calvesCollectionRef);
      calvesDocs.forEach((calfDoc) => {
        updateDoc(
          doc(db, `organizations/${orgId}/calves`, calfDoc.id).withConverter(
            calfConverter
          ),
          {
            ...calfDoc.data(),
            cowName: newCowName,
            updatedAt: getCurrentTimestamp(),
          }
        );
      });
    },
    [orgId]
  );

  const updateCalfLastVaccineDate = useCallback(
    async (calfId: string, vaccineDate: string) => {
      const calf = (await getCalf(calfId)) as Calf;
      const addedVaccineDate = moment(vaccineDate, "MM/DD/YYYY");
      const calfLastVaccineDate = moment(calf.lastVaccineDate, "MM/DD/YYYY");

      if (
        addedVaccineDate.isAfter(calfLastVaccineDate) ||
        !calf.lastVaccineDate
      ) {
        updateCalf(calfId, { ...calf, lastVaccineDate: vaccineDate });
      }
    },
    [getCalf, updateCalf]
  );

  const getLatestVaccineDate = useCallback(
    async (calfId: string, deletedVaccineId?: string) => {
      const vaccines = await getVaccines("calfId", calfId);
      const activeVaccines = vaccines
        .filter((vaccine) => !vaccine.status && vaccine.id !== deletedVaccineId)
        .sort((vaccineA, vaccineB) => {
          if (vaccineA.date! < vaccineB.date!) {
            return 1;
          }
          if (vaccineA.date! > vaccineB.date!) {
            return -1;
          }
          return 0;
        });

      let lastVaccineDate = "";

      if (activeVaccines.length > 0) {
        lastVaccineDate = activeVaccines[0].date;
      }

      updateCalf(calfId, { lastVaccineDate });
    },
    [getVaccines, updateCalf]
  );

  return {
    addCalf,
    getCalf,
    updateCalf,
    deleteCalf,
    getCalfSub,
    updateCalvesCowName,
    getLatestVaccineDate,
    getCalfByNameAndBook,
    getFemaleCalvesByBook,
    updateCalfLastVaccineDate,
  };
}
