import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonCheckbox,
  useIonViewDidEnter,
  IonInput,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { chevronBackOutline } from "ionicons/icons";
import { useParams } from "react-router";
import useCalvesService from "../../../../services/useCalvesService";
import { Calf } from "../../../../models";
import useCowsService from "../../../../services/useCowsService";

type RouteParams = { bookId: string };

export default function Replacements() {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { bookId } = useParams<RouteParams>();

  const { addCow } = useCowsService();
  const { getCowByName } = useCowsService();
  const { getFemaleCalvesByBook, updateCalf } = useCalvesService();

  const [calves, setCalves] = useState<Calf[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCalves, setSelectedCalves] = useState<string[]>([]);
  const [editableCalves, setEditableCalves] = useState<
    { calfId: string; calfName: string; cowName: string }[]
  >([]);

  useIonViewDidEnter(() => {
    setCurrentStep(0);
    setSelectedCalves([]);
  }, []);

  useEffect(() => {
    async function init() {
      const calves = await getFemaleCalvesByBook(bookId);
      setCalves(calves);
    }

    init();
  }, [bookId, getFemaleCalvesByBook]);

  const onCheckChange = (calfId: string) => {
    if (selectedCalves.includes(calfId)) {
      const selectedIndex = selectedCalves.findIndex((id) => calfId === id);
      selectedCalves.splice(selectedIndex, 1);
    } else {
      selectedCalves.push(calfId);
    }

    setSelectedCalves([...selectedCalves]);
  };

  const onNextClicked = async () => {
    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1);
      setEditableCalves(
        getSelectedCalves().map((calf) => ({
          calfId: calf.id,
          calfName: calf.calfName,
          cowName: "",
        }))
      );
    }

    if (currentStep === 1) {
      for (let calf of editableCalves) {
        const cowId = uuidv4();
        addCow(cowId, {
          calfId: calf.calfId,
          cowName: calf.cowName,
        });
        updateCalf(calf.calfId, { isReplace: true });
        router.push(`/books`, "none");
      }
    }
  };

  const getSelectedCalves = (): Calf[] => {
    return selectedCalves.map(
      (calfId) => calves.find((calf) => calf.id === calfId)!
    );
  };

  const onEditCowName = (e: any, idx: number) => {
    editableCalves[idx].cowName = e.target.value;
    setEditableCalves([...editableCalves]);
  };

  const onBlurCowField = async (idx: number) => {
    const { cowName, calfName } = editableCalves[idx];
    const exists = await getCowByName(cowName);
    if (exists) {
      presentAlert({
        header: `Cow name ${cowName} for calf ${calfName} already exists.`,
        buttons: [
          {
            text: "Ok",
            role: "confirm",
            handler: () => {},
          },
        ],
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" routerLink="/books">
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Replacements</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="solid" color={"primary"} onClick={onNextClicked}>
              Next
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {currentStep === 0 && (
          <IonList>
            {calves.map((calf) => (
              <IonItem key={calf.id}>
                <IonCheckbox
                  checked={selectedCalves.includes(calf.id)}
                  onIonChange={(e: any) => onCheckChange(calf.id)}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: 16 }}>{calf.calfName}</span>
                    <span style={{ color: "#f1c40f", fontWeight: "bold" }}>
                      {calf.isReplace ? "Calf already replaced" : ""}
                    </span>
                  </div>
                </IonCheckbox>
              </IonItem>
            ))}
          </IonList>
        )}
        {currentStep === 1 && (
          <IonList>
            {editableCalves.map((calf, idx) => (
              <IonItem key={calf.calfId}>
                <span style={{ fontSize: 16, marginRight: 16, width: 150 }}>
                  {calf.calfName}
                </span>
                <IonInput
                  aria-label="Cow name"
                  placeholder="New cow name"
                  onIonBlur={() => onBlurCowField(idx)}
                  onIonInput={(e: any) => onEditCowName(e, idx)}
                />
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
}
