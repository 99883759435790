import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
} from "@ionic/react";

import "./VaccineCard.css";

interface BatchCardProps {
  batch: any;
}

const BatchCard = ({ batch }: BatchCardProps) => {
  return (
    <IonCard routerLink={`/batches/${batch.id}`}>
      <IonCardHeader>
        <IonCardTitle>{batch.shotName}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className={"VaccineCardContent"}>
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Dose</IonLabel>
            <IonLabel color={"primary"}>{batch.dose}</IonLabel>
          </div>
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Date</IonLabel>
            <IonLabel color={"primary"}>{batch.date}</IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default BatchCard;
