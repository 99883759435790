import { IonPage, useIonRouter } from "@ionic/react";

import BookForm from "../BookForm";
import { Book } from "../../../models";
import useBooksService from "../../../services/useBooksService";

export default function AddBook(): React.ReactElement {
  const router = useIonRouter();
  const { addBook } = useBooksService();
  const onAddBook = async (data: Book) => {
    try {
      await addBook(data);
      router.push("/books");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <IonPage>
      <BookForm title="Add Book" onFormSubmit={onAddBook} />
    </IonPage>
  );
}
