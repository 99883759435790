import {
  IonButton,
  IonCard,
  IonIcon,
  IonLabel,
  useIonRouter,
} from "@ionic/react";
import moment from "moment";
import { useState } from "react";
import { ellipsisHorizontalOutline, sadOutline } from "ionicons/icons";

import "./styles.css";

import MoreModal from "./MoreModal";
import { Calf } from "../../../../models";

interface CalfCardProps {
  calf: Calf;
  bookId: string;
  showCowName: boolean;
  showCalfName: boolean;
}

const CalfCard = ({
  calf,
  bookId,
  showCowName,
  showCalfName,
}: CalfCardProps) => {
  const router = useIonRouter();
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const onCardClicked = (e: any) => {
    if (e.target.id === "more") {
      return setIsMoreOpen(true);
    }

    router.push(`/books/${bookId}/calves/${calf.id}`, "forward");
  };

  const getColor = () => {
    if (calf.isDead) return "#D41924";

    if (calf.isPairout) return "#47E0E8";

    if (calf.isCull) return "#F9B32F";

    const today = moment();

    if (
      calf.lastVaccineDate &&
      today.diff(moment(calf.lastVaccineDate, "MM/DD/YYYY"), "days") <= 14
    )
      return "#4169e1";

    return "#000";
  };

  return (
    <div>
      <IonCard
        className="CalfCard"
        style={{
          border: `1px solid ${
            getColor() === "#000" ? "transparent" : getColor()
          }`,
        }}
        onClick={(e) => onCardClicked(e)}
      >
        <div style={{ color: getColor() }}>
          {showCalfName && <h4 className={"CalfName"}>{calf.calfName}</h4>}
        </div>
        <div
          style={{
            color: getColor(),
            display: "flex",
            flexDirection: "column",
            gap: 5,
            alignItems: "center",
          }}
        >
          {showCowName && <h4 className={"CowName"}>{calf.cowName}</h4>}
          {calf.adoptedBy && <IonIcon icon={sadOutline} />}
        </div>

        <IonLabel color={"primary"} style={{ color: getColor() }}>
          {calf.gender}
        </IonLabel>
        <IonLabel
          color={"primary"}
          style={{ color: getColor() }}
          className={"ion-margin-end"}
        >
          {calf.dateOfBirth || ""}
        </IonLabel>

        <IonButton
          fill="clear"
          id="more"
          size={"small"}
          className="ion-no-padding ion-no-margin"
          onClick={() => setIsMoreOpen(true)}
        >
          <IonIcon icon={ellipsisHorizontalOutline} size="small" id="more" />
        </IonButton>
      </IonCard>
      <MoreModal
        calf={calf}
        isOpen={isMoreOpen}
        close={() => setIsMoreOpen(false)}
      />
    </div>
  );
};

export default CalfCard;
