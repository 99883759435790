import moment from "moment";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonTitle,
} from "@ionic/react";

import { Pregnancy } from "../../../../models/breeding";

interface PregnancyRecordCardProps {
  pregnancyRecord: Pregnancy;
}

export default function PregnancyCard({
  pregnancyRecord,
}: PregnancyRecordCardProps): React.ReactElement {
  return (
    <IonCard>
      <div>
        <IonCardHeader>
          <IonTitle>{`${moment(
            pregnancyRecord.createdAt?.toDate()
          ).year()}`}</IonTitle>
        </IonCardHeader>
      </div>
      <IonCardContent>
        <IonLabel>
          Pregnant: <span>{pregnancyRecord.pregnant ? "Yes" : "No"}</span>
        </IonLabel>
        <IonLabel>
          Due Date: <span>{pregnancyRecord.expectedDateBirth}</span>
        </IonLabel>
        <IonLabel>
          Gender: <span>{pregnancyRecord.gender}</span>
        </IonLabel>
      </IonCardContent>
    </IonCard>
  );
}
