import moment from "moment";
import { App } from "@capacitor/app";
import { OrgData } from "../models/organization";
import { isPlatform, useIonToast } from "@ionic/react";
import { doc, getDoc } from "firebase/firestore";
import db from "../db/firebase";
import { useAuth } from "./AuthProvider";

export const useVersionProvider = () => {
  const auth = useAuth();
  const [present] = useIonToast();
  const checkMobileVersion = async (org: OrgData) => {
    let platform = "";
    if (isPlatform("ios")) platform = "Ios";
    if (isPlatform("android")) platform = "Android";
    const appVersionDocRef = doc(db, `metadata/appversion`);
    const appVersionDoc = await getDoc(appVersionDocRef);
    const appVersionData = appVersionDoc.data();
    const currentVersion = appVersionData![`current${platform}AppVersion`];
    const minimunVersion = appVersionData![`minimum${platform}AppVersion`];
    const lastCheckDate = getLastCheckDate(org);

    const appInfo = await App.getInfo();
    const appVersion = appInfo.version;

    if (parseFloat(minimunVersion) > parseFloat(appVersion)) {
      present({
        duration: 2000,
        position: "top",
        message: `Your current app version (${appVersion}) is lower than the minimum required version (${minimunVersion}). Please update your app.`,
        onDidDismiss: () => auth.logout(),
      });
    }

    if (parseFloat(currentVersion) > parseFloat(appVersion)) {
      if (lastCheckDate) {
        let today = moment();
        let lastCheck = moment(lastCheckDate, "MM/DD/YYYY");

        if (today.diff(lastCheck, "w") >= 1) {
          await auth.updateVersionCheckDate(
            moment().format("MM/DD/YYYY"),
            platform
          );

          present({
            duration: 2000,
            position: "top",
            message: `Your current app version (${appVersion}) is lower than the current version (${currentVersion}). Update your app soon.`,
          });
        }
      } else {
        await auth.updateVersionCheckDate(
          moment().format("MM/DD/YYYY"),
          platform
        );

        present({
          duration: 2000,
          position: "top",
          message: `Your current app version (${appVersion}) is lower than the current version (${currentVersion}). Update your app soon.`,
        });
      }
    }
  };

  const getLastCheckDate = (org: OrgData) => {
    if (isPlatform("ios")) {
      return org.versionNumberLastCheckDateIos || null;
    }

    if (isPlatform("android")) {
      return org.versionNumberLastCheckDateAndroid || null;
    }
  };

  return {
    checkMobileVersion,
  };
};
