import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  isPlatform,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";
import moment from "moment";

import { useAuth } from "../../Auth/AuthProvider";
import { PurchasesStoreProduct } from "@revenuecat/purchases-capacitor";

export default function Subscription() {
  //const router = useIonRouter();
  //const [presentAlert] = useIonAlert();
  const {
    currentOrg,
    accountStatus,
    subscribe,
    appOfferings,
    restorePurchases,
  } = useAuth();

  const getTrialMessage = () => {
    const trialDays = currentOrg?.trialDays!;
    const days = moment().diff(
      moment(currentOrg?.createdAt, "MM/DD/YYYY"),
      "days"
    );

    if (days > trialDays) {
      return 0;
    }

    return trialDays - days;
  };

  const accountStatusMessage = () => {
    if (accountStatus === "subscribed") {
      return `Your subscription renewal date is ${currentOrg?.expireDate}`;
    }

    if (accountStatus === "trial") {
      return `You have ${getTrialMessage()} days left in your trial`;
    }

    return "";
  };

  const onSubscribe = async (product: PurchasesStoreProduct) => {
    try {
      await subscribe(product);
      // presentAlert({
      //   header:
      //     "You are now subscribed. Please click on okay to be redirected to the books page",
      //   buttons: [
      //     {
      //       text: "Ok",
      //       role: "confirm",
      //       handler: () => {
      //         router.push("/books");
      //       },
      //     },
      //   ],
      // });
    } catch (err) {}
  };

  return (
    <IonCard>
      <IonCardHeader>{accountStatusMessage()}</IonCardHeader>
      {isPlatform("mobile") &&
        appOfferings &&
        appOfferings.current !== null &&
        appOfferings.current.availablePackages.map(({ product }) => (
          <IonItem key={product.identifier}>
            <IonLabel className={"ion-text-wrap"}>
              {/*{sku.product.description}*/}
              Buy or Renew Annual Subscription
            </IonLabel>
            <IonButton
              size={"small"}
              fill={"outline"}
              onClick={() => onSubscribe(product)}
              disabled={accountStatus === "subscribed"}
            >{`${product.currencyCode} ${product.price.toFixed(2)}`}</IonButton>
          </IonItem>
        ))}
      {isPlatform("mobile") && (
        <IonItem>
          <IonButton
            size={"small"}
            fill={"outline"}
            onClick={restorePurchases}
            disabled={accountStatus === "subscribed"}
          >
            Restore Purchases
          </IonButton>
        </IonItem>
      )}
      {!isPlatform("mobile") && (
        <h6 className={"ion-padding-horizontal"}>
          {accountStatus === "subscribed"
            ? "You are subscribed."
            : "Buy subscription on mobile."}
        </h6>
      )}
      <IonCardHeader>
        <IonCardTitle style={{ textAlign: "center" }}>FAQ</IonCardTitle>
      </IonCardHeader>

      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Subscription Duration</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              All of our Subscriptions have a duration of ONE (1) year from the
              date of purchase.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Title</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Our Product Names are Shown in the Collapsible Below &quot;My
              Subscription Status&quot;
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Payment</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Payment is made by paying the listed price to the app store at the
              time of purchase.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Renewal</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              All subscriptions renew annually on the anniversary date of your
              original subscription. You can maintain your subscription / cancel
              your subscription by clicking the &quot;View in Store&quot;
              button.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Managing / Cancelling</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              All subscriptions renew annually on the anniversay date of your
              original subscription. You can maintain your subscription / cancel
              your subscription by clicking the &quot;View App Store&quot;
              button below.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Canceling</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Subscriptions may be managed by the user and auto-renewal may be
              turned off by going to the user's Account Settings after purchase.
              Your account will be charged for renewal within 24-hours prior to
              the end of the current period, and identify the cost of the
              renewal
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Links</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Please click the button below &quot;View in Store&quot; to view or
              manage your subscription in the Store.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Un-used Trial Period</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Upon purchasing a subscription you agree and understand that you
              are forfeiting the value of any unused trial period.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Un-used Trial Period</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              Upon purchasing a subscription you agree and understand that you
              are forfeiting the value of any unused trial period.
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Privacy Policy</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://thecalvingbook.wordpress.com/privacy-policy/"
              >
                Link
              </a>
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol size="5">
            <IonCardHeader>
              <IonCardSubtitle>Terms of Use</IonCardSubtitle>
            </IonCardHeader>
          </IonCol>
          <IonCol size="7">
            <IonCardContent>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://thecalvingbook.wordpress.com/terms-of-use/"
              >
                Link
              </a>
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
}
