import {
  IonFab,
  IonPage,
  IonIcon,
  IonContent,
  IonFabButton,
  IonSearchbar,
  IonButton,
  useIonAlert,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { Tab, Tabs } from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { useEffect, useState } from "react";

import "./styles.css";

import BookCard from "./BookCard";
import { Book } from "../../../models";
import Header from "../../../components/Header";
import useBooksService from "../../../services/useBooksService";

export default function BooksList(): React.ReactElement {
  const [presentAlert] = useIonAlert();
  const { getBooksSub, updateBook } = useBooksService();
  const [books, setBooks] = useState<Book[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState<string[]>([]);
  const [currentSegment, setCurrentSegment] = useState<string>("active");

  useEffect(() => {
    const unsubscribe = getBooksSub((books) => {
      setBooks(
        books.sort((book1, book2) => {
          if (book1.createdAt! < book2.createdAt!) {
            return 1;
          }
          if (book1.createdAt! > book2.createdAt!) {
            return -1;
          }
          return 0;
        })
      );
    }, currentSegment);

    return () => unsubscribe();
  }, [getBooksSub, currentSegment]);

  const filterBooks = () => {
    if (!searchValue) return books;

    return books.filter((book) =>
      book.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const onCheckChange = (bookId: string) => {
    if (selectedBooks.includes(bookId)) {
      const selectedIndex = selectedBooks.findIndex((id) => bookId === id);
      selectedBooks.splice(selectedIndex, 1);
    } else {
      selectedBooks.push(bookId);
    }

    setSelectedBooks([...selectedBooks]);
  };

  return (
    <IonPage>
      <Header
        title={"Books"}
        rightComponent={
          selectMultiple ? (
            <>
              <IonButton
                onClick={() => {
                  let status = "archived";
                  let title =
                    "Are you sure you want to archive the selected books?";

                  presentAlert({
                    header: title,
                    buttons: [
                      {
                        text: "Cancel",
                        role: "cancel",
                        handler: () => {},
                      },
                      {
                        text: "Yes",
                        role: "confirm",
                        handler: async () => {
                          if (selectedBooks && selectedBooks.length) {
                            for (let selectedBook of selectedBooks) {
                              updateBook(selectedBook, { status });
                            }
                          }
                          // router.push(`/books`, "back");
                          // if (clearSelectedBooks) clearSelectedBooks();
                          // modal.current?.dismiss();
                          setSelectedBooks([]);
                          setSelectMultiple(false);
                        },
                      },
                    ],
                  });
                }}
              >
                Done
              </IonButton>
              <IonButton
                onClick={() => {
                  setSelectedBooks([]);
                  setSelectMultiple(false);
                }}
              >
                Cancel
              </IonButton>
            </>
          ) : null
        }
      >
        <IonSearchbar
          value={searchValue}
          onIonInput={(e: any) => {
            setSearchValue(e.target.value || "");
          }}
          className="ion-padding-horizontal"
        />
      </Header>
      <IonContent className={"BooksList"} scrollY={false}>
        <div>
          <Tabs
            variant={"fullWidth"}
            value={currentSegment}
            onChange={(e, value) => {
              setCurrentSegment(value);
            }}
          >
            <Tab label={"Active"} value={"active"} />
            <Tab label={"Archived"} value={"archived"} />
          </Tabs>
          <Virtuoso
            //data={books}
            style={{ marginBottom: 16 }}
            data={filterBooks()}
            itemContent={(index, book: Book) => (
              <BookCard
                book={book}
                key={book.id}
                selectedBooks={selectedBooks}
                selectMultiple={selectMultiple}
                clearSelectedBooks={() => setSelectedBooks([])}
                onSelectMultiple={() => setSelectMultiple(true)}
                onSelectBook={(bookId: string) => onCheckChange(bookId)}
              />
            )}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-book" size={"small"}>
            <IonIcon icon={add} size={"small"}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
}
