import { IonCard, IonInput } from "@ionic/react";
import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { Sire } from "../../../../models";
import { useBreedingForm } from "./useBreedingForm";
import FormControl from "../../../../components/FormControl/FormControl";
import useSiresService from "../../../../services/useSiresService";
import AutoComplete from "../../../../components/AutoComplete";

export default function Form(): React.ReactElement {
  const formApi = useFormContext();
  const { sires, refetchSires } = useBreedingForm();
  const { addQuickSire } = useSiresService();
  const { control, register, setValue } = formApi;

  const onUpdateSire = async (sireId: string, data: Sire) => {
    try {
      //addSire(data);
      addQuickSire(sireId, data);
      refetchSires();
    } catch (err) {}
  };

  return (
    <form>
      <IonCard>
        <FormControl label={"Attempt Date"}>
          <Controller
            name="attemptDate"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("attemptDate", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl label={"Sire ID"}>
          <Controller
            name="sireId"
            control={control}
            render={({ field }) => (
              <AutoComplete
                value={field.value}
                onValueChange={(value) => {
                  setValue("sireId", value.id, { shouldDirty: true });
                }}
                onAddNewValue={(value) => {
                  //insertNewLocation(value.label);
                  onUpdateSire(value.id, {
                    id: value.id,
                    sireName: value.label,
                  });
                }}
                inputLabel="Select or create a location"
                options={sires.map((sire) => ({
                  label: sire.sireName,
                  id: sire.id,
                }))}
              />
            )}
          />
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
            }}
          >
            <Controller
              name="sireId"
              control={control}
              render={({ field }) => (
                <IonSelect
                  value={field.value}
                  placeholder="Select"
                  onIonChange={(e) => setValue("sireId", e.target.value)}
                >
                  {sires.map((sire) => (
                    <IonSelectOption key={sire.id} value={sire.id}>
                      {sire.sireName}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
            <IonIcon
              icon={add}
              size="large"
              onClick={() => setShowSireModal(true)}
            />
          </div> */}
        </FormControl>
        <FormControl label={"AI Tech"}>
          <IonInput
            {...register("aiTech")}
            type={"text"}
            placeholder="AI Tech"
          />
        </FormControl>
      </IonCard>
      {/* <SireModal
        isOpen={showSireModal}
        close={() => setShowSireModal(false)}
        onFormSubmit={onAddSire}
      /> */}
    </form>
  );
}
