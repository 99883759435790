import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";
import { Calf } from "../../models";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import Segments from "../Segments";
import { useVaccines } from "../Vaccines/useVaccines";

interface CalfDetailProps {
  calf: Calf;
}

export default function CalfDetail({
  calf,
}: CalfDetailProps): React.ReactElement {
  const { vaccines } = useVaccines("calfId", calf.id);
  const [currentSegment, setCurrentSegment] = useState<string>("general");

  return (
    <IonContent>
      <Tabs
        variant={"fullWidth"}
        value={currentSegment}
        onChange={(e, value) => {
          setCurrentSegment(value);
        }}
      >
        <Tab label={"General Info"} value={"general"} />
        <Tab label={"Weaning Records"} value={"weaning"} />
        <Tab label={"Vaccines"} value={"vaccine"} />
      </Tabs>
      <Segments
        currentSegment={currentSegment}
        segments={[
          {
            id: "general",
            render: () => (
              <IonList>
                <IonItem>
                  <IonLabel>Cow ID</IonLabel>
                  <IonLabel>{calf.cowName}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Gender</IonLabel>
                  <IonLabel>{calf.gender}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Weight</IonLabel>
                  <IonLabel>{calf.weight}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Sire</IonLabel>
                  <IonLabel>{calf.sireName}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Color</IonLabel>
                  <IonLabel>{calf.color}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Calving Ease</IonLabel>
                  <IonLabel>{calf.calvinEase}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Date of Birth</IonLabel>
                  <IonLabel>{calf.dateOfBirth}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Udder Grade</IonLabel>
                  <IonLabel>{calf.udderGrade}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Tag Color</IonLabel>
                  <IonLabel>{calf.tagColor}</IonLabel>
                </IonItem>
              </IonList>
            ),
          },
          {
            id: "weaning",
            render: () => (
              <IonList>
                <IonItem>
                  <IonLabel>Weaning Date</IonLabel>
                  <IonLabel>{calf.weaningDate}</IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>Weaning Weight</IonLabel>
                  <IonLabel>{calf.weaningWeight}</IonLabel>
                </IonItem>
              </IonList>
            ),
          },
          {
            id: "vaccine",
            render: () =>
              vaccines.map((vaccine) => (
                <IonCard key={vaccine.id}>
                  <IonCardHeader>
                    <IonCardTitle>{`${vaccine.isBatch ? "(Batch)" : ""}${
                      vaccine.shotName
                    }`}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <div className={"VaccineCardContent"}>
                      <div className="VaccineCardField">
                        <IonLabel color={"medium"}>Dose</IonLabel>
                        <IonLabel color={"primary"}>{vaccine.dose}</IonLabel>
                      </div>
                      <div className="VaccineCardField">
                        <IonLabel color={"medium"}>Date</IonLabel>
                        <IonLabel color={"primary"}>{vaccine.date}</IonLabel>
                      </div>
                    </div>
                  </IonCardContent>
                </IonCard>
              )),
          },
        ]}
      />
    </IonContent>
  );
}
