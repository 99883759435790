import { useState, useEffect } from "react";
import { query, collection, getDocs, setDoc, doc } from "firebase/firestore";

import db from "../db/firebase";
import { useAuth } from "../Auth/AuthProvider";

export function useLocations() {
  const auth = useAuth();
  const orgId = auth.currentUser?.uid!;
  const [locations, setLocations] = useState<string[]>([]);

  useEffect(() => {
    async function fetchLocations() {
      const locationsCollectionRef = query(
        collection(db, `organizations/${orgId}/refLocations`)
      );
      const locationsDocs = await getDocs(locationsCollectionRef);
      setLocations(locationsDocs.docs.map((doc) => doc.id));
    }

    fetchLocations();
  }, [orgId]);

  const insertNewLocation = async (location?: string) => {
    if (!!location && !locations.includes(location)) {
      setDoc(doc(db, `organizations/${orgId}/refLocations`, location), {});
      setLocations([...locations, location]);
    }
  };

  return {
    locations,
    insertNewLocation,
  };
}
