import moment from "moment";
import { useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react";

import { Pregnancy } from "../../../../models/breeding";
import { useGoBack } from "../../../../hooks/useGoBack";
import useBreedingService from "../../../../services/useBreedingService";
import Header from "../../../../components/Header";
import PregnancyForm from "./Form";
import { useEffect } from "react";

type RouteParams = { cowId: string };
export default function PregnancyRecord() {
  const router = useIonRouter();
  const { cowId } = useParams<RouteParams>();
  const { addPregnancy, getLastAttempt } = useBreedingService();

  const pregnancyFormApi = useForm<Pregnancy>({
    defaultValues: {
      gender: "",
      twins: false,
      pregnant: false,
      expectedDateBirth: undefined,
    },
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = pregnancyFormApi;

  useEffect(() => {
    const fetchLastAttempt = async () => {
      const year = moment().year();

      const lastAttempt = await getLastAttempt(cowId, year);

      if (lastAttempt) {
        const attemptDate = lastAttempt.attemptDate;
        const momentDate = moment(attemptDate, "MM/DD/YYYY");
        setValue(
          "expectedDateBirth",
          momentDate.add(282, "days").format("MM/DD/YYYY"),
          {
            shouldDirty: true,
          }
        );
      }
    };

    fetchLastAttempt();
  }, [cowId, setValue, getLastAttempt]);

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: `/cows/${cowId}`,
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    const pregnancy: Pregnancy = {
      ...data,
      cowId,
    };

    addPregnancy({
      ...pregnancy,
    });
    reset();
    router.push(`/cows/${cowId}`);
  });

  return (
    <IonPage>
      <Header
        title={"Add Pregnancy"}
        onGoBack={goBack}
        rightComponent={
          <IonButton
            fill="clear"
            type="submit"
            onClick={onSubmit}
            disabled={!isValid}
            style={{ color: "#4888AE" }}
          >
            Save
          </IonButton>
        }
      />
      <IonContent>
        <FormProvider {...pregnancyFormApi}>
          <PregnancyForm />
        </FormProvider>
      </IonContent>
    </IonPage>
  );
}
