import {
  query,
  where,
  collection,
  onSnapshot,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import moment from "moment";
import { useCallback } from "react";

import db from "../db/firebase";
import { Breeding } from "../models";
import { getCurrentTimestamp } from "../utils";
import { useAuth } from "../Auth/AuthProvider";
import breedingConverter from "../db/converters/breedingConverter";
import pregnancyConverter from "../db/converters/pregnancyConverter";
import breedingAttemptConverter from "../db/converters/breedingAttemptConverter";
import { BreedingAttempt, BreedingRecord, Pregnancy } from "../models/breeding";

export default function useBreedingService() {
  const { currentUser } = useAuth();
  const orgId = currentUser?.uid!;

  const getBreedingSub = useCallback(
    async (
      breedId: string,
      cb: (breeding: BreedingRecord | undefined) => void
    ) => {
      const breedingDocRef = doc(
        db,
        `organizations/${orgId}/breedings`,
        breedId
      ).withConverter(breedingConverter);
      onSnapshot(breedingDocRef, (snapshot) => {
        cb(snapshot.data());
      });
    },
    [orgId]
  );

  const getBreedings = useCallback(
    async (cowId: string) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedings`),
        where("cowId", "==", cowId)
      ).withConverter(breedingConverter);
      const breedingsDocs = await getDocs(breedingsCollectionRef);
      return breedingsDocs.docs.map((doc) => doc.data());
    },
    [orgId]
  );

  const getPregnancies = useCallback(
    async (cowId: string) => {
      const pregnanciesCollectionRef = query(
        collection(db, `organizations/${orgId}/pregnancies`),
        where("cowId", "==", cowId)
      ).withConverter(pregnancyConverter);
      const pregnanciesDocs = await getDocs(pregnanciesCollectionRef);
      return pregnanciesDocs.docs.map((doc) => doc.data());
    },
    [orgId]
  );

  const getPregnanciesSub = useCallback(
    async (cowId: string, cb: (pregnancies: Pregnancy[]) => void) => {
      const pregnanciesCollectionRef = query(
        collection(db, `organizations/${orgId}/pregnancies`),
        where("cowId", "==", cowId)
      ).withConverter(pregnancyConverter);

      return onSnapshot(pregnanciesCollectionRef, (snapshot) => {
        cb(
          snapshot.docs
            .map((doc) => doc.data())
            .sort((pregnancyA, pregnancyB) => {
              return (pregnancyA.createdAt || "") > (pregnancyB.createdAt || "")
                ? 1
                : -1;
            })
        );
      });
    },
    [orgId]
  );

  const getBreedingsSub = useCallback(
    async (cowId: string, cb: (breedings: BreedingRecord[]) => void) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedings`),
        where("cowId", "==", cowId)
      ).withConverter(breedingConverter);
      return onSnapshot(breedingsCollectionRef, (snapshot) => {
        cb(
          snapshot.docs
            .map((doc) => doc.data())
            .sort((breedA, breedB) => {
              return (breedA.createdAt || "") > (breedB.createdAt || "")
                ? 1
                : -1;
            })
        );
      });
    },
    [orgId]
  );

  const getBreedingAttemptsSub = useCallback(
    async (cowId: string, cb: (breedings: BreedingAttempt[]) => void) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedingAttempts`),
        where("cowId", "==", cowId.trim())
      ).withConverter(breedingAttemptConverter);
      return onSnapshot(breedingsCollectionRef, (snapshot) => {
        cb(
          snapshot.docs
            .map((doc) => doc.data())
            .sort((breedA, breedB) => {
              return (breedA.createdAt || "") > (breedB.createdAt || "")
                ? 10
                : -1;
            })
        );
      });
    },
    [orgId]
  );

  const getLastAttempt = useCallback(
    async (cowId: string, year: number) => {
      const breedingsCollectionRef = query(
        collection(db, `organizations/${orgId}/breedingAttempts`),
        where("cowId", "==", cowId),
        orderBy("attemptDate", "desc")
      ).withConverter(breedingAttemptConverter);

      const breedingsDocs = await getDocs(breedingsCollectionRef);
      const breedingAttempts = breedingsDocs.docs.map((doc) => doc.data());
      const currentYearAttempts = breedingAttempts.filter((attempt) => {
        const attemptYear = moment(attempt.attemptDate, "MM/DD/YYYY").year();

        return attemptYear === year;
      });
      console.log("currentYearAttempts", currentYearAttempts);

      if (currentYearAttempts.length) return currentYearAttempts[0];
    },
    [orgId]
  );

  const addBreedAttempt = useCallback(
    (breedAttempt: BreedingAttempt) => {
      addDoc(
        collection(db, `organizations/${orgId}/breedingAttempts`).withConverter(
          breedingAttemptConverter
        ),
        {
          ...breedAttempt,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const addPregnancy = useCallback(
    (pregnancy: Pregnancy) => {
      addDoc(
        collection(db, `organizations/${orgId}/pregnancies`).withConverter(
          pregnancyConverter
        ),
        {
          ...pregnancy,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const addBreed = useCallback(
    (breed: BreedingRecord) => {
      addDoc(
        collection(db, `organizations/${orgId}/breedings`).withConverter(
          breedingConverter
        ),
        {
          ...breed,
          createdAt: getCurrentTimestamp(),
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  const updateBreed = useCallback(
    (breedId: string, breed: Partial<Breeding>): void => {
      updateDoc(
        doc(db, `organizations/${orgId}/breedings`, breedId).withConverter(
          breedingConverter
        ),
        {
          ...breed,
          updatedAt: getCurrentTimestamp(),
        }
      );
    },
    [orgId]
  );

  return {
    addBreed,
    updateBreed,
    addPregnancy,
    getBreedings,
    getPregnancies,
    getBreedingSub,
    getLastAttempt,
    getBreedingsSub,
    addBreedAttempt,
    getPregnanciesSub,
    getBreedingAttemptsSub,
  };
}
