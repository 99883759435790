import { useEffect } from "react";
import { App } from "@capacitor/app";
import { isPlatform } from "@ionic/react";

import { useAuth } from "./AuthProvider";
import { useVersionProvider } from "./useVersionProvider";
export const VersionWrapper = ({ children }: { children: any }) => {
  const auth = useAuth();
  const { checkMobileVersion } = useVersionProvider();

  useEffect(() => {
    if (isPlatform("android") || isPlatform("ios")) {
      checkMobileVersion(auth.currentOrg!);
      App.addListener("resume", () => {
        checkMobileVersion(auth.currentOrg!);
      });
    }
  }, [auth.currentOrg, checkMobileVersion]);

  return <>{children}</>;
};
