import { useParams } from "react-router";
import { IonPage, useIonRouter } from "@ionic/react";

import { Vaccine } from "../../../models";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";
import useCowsService from "../../../services/useCowsService";

type RouteParams = { cowId: string };

export default function AddCowVaccine(): React.ReactElement {
  const router = useIonRouter();
  const { updateCow } = useCowsService();
  const { addVaccine } = useVaccinesService();
  const { cowId } = useParams<RouteParams>();

  const onAddCowVaccine = async (data: Vaccine) => {
    addVaccine({
      ...data,
      cowId,
    });
    updateCow(cowId, { lastVaccineDate: data.date });
    router.push(`/cows/${cowId}`);
  };

  return (
    <IonPage>
      <VaccineForm
        title="Add Vaccine"
        backLink={`/cows/${cowId}`}
        onFormSubmit={onAddCowVaccine}
      />
    </IonPage>
  );
}
