import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonTextarea,
  isPlatform,
} from "@ionic/react";
import "./styles.css";

import { Note } from "../../../../models";

interface NoteCardProps {
  note: Note;
}

const NoteCard = ({ note }: NoteCardProps) => {
  const shortText = note.text.substring(0, 100) + "...";

  const numberOfRows = () => {
    if (isPlatform("mobile") || isPlatform("mobileweb")) {
      return 3;
    }

    return 1;
  };

  return (
    <IonCard routerLink={`/notes/${note.id}`}>
      <IonCardContent>
        <div className="NoteCardContent">
          <IonTextarea
            disabled
            value={shortText}
            style={{
              color: "black",
            }}
            rows={numberOfRows()}
          ></IonTextarea>
          <div className={"NoteDate"}>
            <IonLabel color={"primary"}> {note.date}</IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default NoteCard;
