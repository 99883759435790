import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef } from "react";

import { Cow } from "../../../models";

interface ExcludeCowsModalProps {
  cows: Cow[];
  isOpen: boolean;
  close: () => void;
  excludedCows: Set<string>;
  onCowClicked: (cowId: string) => void;
}

export default function ExcludeCowsModal({
  cows,
  close,
  isOpen,
  excludedCows,
  onCowClicked,
}: ExcludeCowsModalProps): React.ReactElement {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.dismiss()}>Done</IonButton>
          </IonButtons>
          <IonTitle>Exclude Cows</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {cows.map((cow) => (
          <IonItem key={cow.id}>
            <IonCheckbox
              value={cow.id}
              checked={excludedCows.has(cow.id)}
              onIonChange={(e: any) => onCowClicked(cow.id)}
            />
            <IonLabel>{cow.cowName}</IonLabel>
          </IonItem>
        ))}
      </IonContent>
    </IonModal>
  );
}
