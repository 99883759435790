import { useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react";

import Form from "../BreedingForm/Form";
import Header from "../../../../components/Header";
import { useGoBack } from "../../../../hooks/useGoBack";
import { BreedingAttempt } from "../../../../models/breeding";
import useBreedingService from "../../../../services/useBreedingService";

type RouteParams = { cowId: string };
export default function BreedingRecordForm() {
  const router = useIonRouter();
  const { cowId } = useParams<RouteParams>();
  const { addBreedAttempt } = useBreedingService();

  const attemptFormApi = useForm<BreedingAttempt>({
    defaultValues: {
      aiTech: "",
      sireId: "",
      attemptDate: undefined,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = attemptFormApi;

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: `/cows/${cowId}`,
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    console.log("data", data);

    const breedingRecord: BreedingAttempt = {
      ...data,
      cowId,
    };

    addBreedAttempt({
      ...breedingRecord,
    });
    reset();
    router.push(`/cows/${cowId}`);
  });

  return (
    <IonPage>
      <Header
        title={"Add Breeding Record"}
        onGoBack={goBack}
        rightComponent={
          <IonButton
            fill="clear"
            type="submit"
            onClick={onSubmit}
            disabled={!isValid}
            style={{ color: "#4888AE" }}
          >
            Save
          </IonButton>
        }
      />
      <IonContent>
        <FormProvider {...attemptFormApi}>
          <Form />
        </FormProvider>
      </IonContent>
    </IonPage>
  );
}
