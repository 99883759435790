import { IonCard, IonInput } from "@ionic/react";

import FormControl from "../../../components/FormControl/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment, { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { Calf } from "../../../models";

import "./styles.css";

export default function WeaningForm(): React.ReactElement {
  const formApi = useFormContext<Calf>();
  const { control, register, setValue, watch } = formApi;

  const calculateAverageDailyGain = () => {
    const { weight, dateOfBirth, weaningDate, weaningWeight } = watch();

    // (Weaning weight- birth weight )/(weaning date - birth date)
    if (weight && dateOfBirth && weaningDate && weaningWeight) {
      const momentDateOfBirth = moment(dateOfBirth, "MM/DD/YYYY");
      const momentWeaningDate = moment(weaningDate, "MM/DD/YYYY");

      const days = momentWeaningDate.diff(momentDateOfBirth, "days");

      if (!days) return "";

      return ((weaningWeight - weight) / days).toFixed(2);
    }

    return "";
  };

  return (
    <IonCard>
      <FormControl label={"Weaning Date"}>
        <Controller
          name="weaningDate"
          control={control}
          render={({ field }) => {
            return (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("weaningDate", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            );
          }}
        />
      </FormControl>
      <FormControl label={"Weaning Weight"}>
        <IonInput
          {...register("weaningWeight", {
            valueAsNumber: true,
          })}
          type="number"
          onIonChange={(e: any) =>
            setValue("weaningWeight", e.target.value as number, {
              shouldDirty: true,
            })
          }
        />
      </FormControl>
      <FormControl label={"Average Daily Gain"}>
        <IonInput disabled type="number" value={calculateAverageDailyGain()} />
      </FormControl>
    </IonCard>
  );
}
