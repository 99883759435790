import {
  IonItem,
  IonTitle,
  IonButton,
  IonLabel,
  IonModal,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonContent,
  IonCheckbox,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { Calf } from "../../../models";
import useBooksService from "../../../services/useBooksService";

interface ExcludeCalvesModalProps {
  close: () => void;
  currentBook: string;
  excludedCalves: string[];
  onCalfClicked: (calfId: string, bookId: string) => void;
}

export default function ExcludeCalvesModal({
  close,
  currentBook,
  onCalfClicked,
  excludedCalves,
}: ExcludeCalvesModalProps): React.ReactElement {
  const { getBookCalvesSub } = useBooksService();
  const modal = useRef<HTMLIonModalElement>(null);
  const [calves, setCalves] = useState<Calf[]>([]);

  useEffect(() => {
    if (currentBook) {
      getBookCalvesSub(currentBook, setCalves);
    }
  }, [currentBook, getBookCalvesSub]);

  return (
    <IonModal isOpen={!!currentBook} ref={modal} onWillDismiss={(e) => close()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.dismiss()}>Done</IonButton>
          </IonButtons>
          <IonTitle>Exclude Calves</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {calves.map((calf) => (
          <IonItem key={calf.id}>
            <IonCheckbox
              value={calf.id}
              checked={excludedCalves.includes(calf.id)}
              onIonChange={(e: any) => onCalfClicked(calf.id, currentBook)}
            />
            <IonLabel>{calf.calfName}</IonLabel>
          </IonItem>
        ))}
      </IonContent>
    </IonModal>
  );
}
