import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  useIonRouter,
  useIonAlert,
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonCard,
} from "@ionic/react";
import moment from "moment";
import { chevronForward } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import "./styles.css";

import Form from "./Form";
import { Calf, Cow } from "../../../models";
import Header from "../../../components/Header";
import Segments from "../../../components/Segments";
import Vaccines from "../../../components/Vaccines";
import { useGoBack } from "../../../hooks/useGoBack";
import { useLocations } from "../../../hooks/useLocations";
import useCowsService from "../../../services/useCowsService";
import Breeding from "../Breeding";
import CalfModal from "../../../components/CalfModal";
import { Tab, Tabs } from "@mui/material";
import PregnancyList from "../Pregnancy";
import useCalvesService from "../../../services/useCalvesService";

interface CowFormProps {
  title: string;
  cow?: Partial<Cow>;
  onFormSubmit: (data: Cow) => void;
}

export default function CowForm({
  cow,
  title,
  onFormSubmit,
}: CowFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { getCalf } = useCalvesService();
  const { insertNewLocation } = useLocations();
  const { deleteCow, getCowsCalves } = useCowsService();
  const [calves, setCalves] = useState<Calf[]>([]);
  const [selectedCalf, setSelectedCalf] = useState<Calf | null>(null);
  const [currentSegment, setCurrentSegment] = useState<string>("general");
  const formApi = useForm<Cow>({
    defaultValues: {
      cowName: cow?.cowName || "",
      location: cow?.location || "",
      purchasePrice: cow?.purchasePrice || undefined,
      dateOfPurchase: cow?.dateOfPurchase || undefined,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields },
  } = formApi;

  useEffect(() => {
    async function fetchCalves(cowName: string) {
      const calves = await getCowsCalves(cowName);
      setCalves(calves);
    }

    if (cow) {
      //getCowCalvesSub(cow.cowName!, setCalves);
      fetchCalves(cow.cowName!);
    }
  }, [cow, getCowsCalves]);

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: "/cows",
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = (data: Cow) => {
    onFormSubmit(data);
    insertNewLocation(data.location);
    if (cow) {
      reset(data);
    } else {
      reset();
    }
  };

  const onDeleteCow = (cowId: string) => {
    presentAlert({
      header: "Are you sure you want to delete the cow?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            deleteCow(cowId);
            router.push(`/cows`, "none");
          },
        },
      ],
    });
  };

  const onGetCalfData = async (calfId: string) => {
    const calf = await getCalf(calfId);
    setSelectedCalf(calf!);
  };

  return (
    <>
      <Header
        title={title}
        onGoBack={goBack}
        rightComponent={
          <>
            {currentSegment === "general" && (
              <IonButtons slot="end">
                {cow && !isDirty && !(Object.keys(dirtyFields).length > 0) && (
                  <IonButton
                    size={"small"}
                    fill={"clear"}
                    onClick={() => {
                      onDeleteCow(cow.id!);
                    }}
                  >
                    <IonIcon src={"assets/trash.svg"} color={"danger"} />
                  </IonButton>
                )}
                <div style={{ width: 15 }} />
                {/* {isDirty && Object.keys(dirtyFields).length > 0 && ( */}
                <IonButton
                  fill="clear"
                  type="submit"
                  disabled={!isValid}
                  style={{ color: "#4888AE" }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </IonButton>
                {/* )} */}
                {cow?.calfId && (
                  <IonButton onClick={() => onGetCalfData(cow.calfId!)}>
                    Calf Data
                  </IonButton>
                )}
              </IonButtons>
            )}
          </>
        }
      />
      <IonContent>
        {cow && (
          // <IonToolbar>
          //   <IonSegment
          //     value={currentSegment}
          //     disabled={isDirty}
          //     onIonChange={(e) => setCurrentSegment(e.detail.value as string)}
          //   >
          //     <IonSegmentButton value="general">General Info</IonSegmentButton>
          //     <IonSegmentButton value="vaccine">Vaccines</IonSegmentButton>
          //     <IonSegmentButton value="breeding">Breeding</IonSegmentButton>
          //     <IonSegmentButton value="pregnancy">Pregnancy</IonSegmentButton>
          //   </IonSegment>
          // </IonToolbar>
          <Tabs
            variant={"fullWidth"}
            value={currentSegment}
            onChange={(e, value) => {
              setCurrentSegment(value);
            }}
          >
            <Tab label={"General Info"} value={"general"} disabled={isDirty} />
            <Tab label={"Vaccines"} value={"vaccine"} disabled={isDirty} />
            <Tab label={"Breeding"} value={"breeding"} disabled={isDirty} />
            <Tab label={"Pregnancy"} value={"pregnancy"} disabled={isDirty} />
          </Tabs>
        )}
        <Segments
          currentSegment={currentSegment}
          segments={[
            {
              id: "general",
              render: () => (
                <>
                  <FormProvider {...formApi}>
                    <Form onFormSubmit={onSubmit} />
                  </FormProvider>
                  <IonCard>
                    <IonList>
                      <IonListHeader>
                        {/* <IonLabel>{`${cow?.cowName} ’s calves`}</IonLabel> */}
                        <IonLabel>Calving Records</IonLabel>
                      </IonListHeader>
                      <IonItem>
                        <IonLabel>Calf Name</IonLabel>
                        <IonLabel>Sire Name</IonLabel>
                        <IonLabel>Birth Year</IonLabel>
                      </IonItem>
                      {calves.map((calf) => (
                        <IonItem
                          key={calf.id}
                          onClick={() => setSelectedCalf(calf)}
                        >
                          <IonIcon icon={chevronForward} slot="end" />
                          <IonLabel>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{calf.calfName}</span>
                            </div>
                          </IonLabel>
                          <IonLabel>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{calf.sireName}</span>
                            </div>
                          </IonLabel>
                          <IonLabel>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>
                                {moment(calf.dateOfBirth, "MM/DD/YYYY").format(
                                  "YYYY"
                                )}
                              </span>
                            </div>
                          </IonLabel>
                        </IonItem>
                      ))}
                    </IonList>
                  </IonCard>
                  {selectedCalf && (
                    <CalfModal
                      calf={selectedCalf}
                      onClose={() => setSelectedCalf(null)}
                    />
                  )}
                </>
              ),
            },
            {
              id: "vaccine",
              render: () => (
                <Vaccines
                  searchKey="cowId"
                  mainUrl={`cows/${cow?.id}`}
                  searchId={cow?.id as string}
                />
              ),
            },
            {
              id: "breeding",
              render: () => <Breeding cowId={cow?.cowName!} />,
            },
            {
              id: "pregnancy",
              render: () => <PregnancyList cowId={cow?.id!} />,
            },
          ]}
        />
      </IonContent>
    </>
  );
}
