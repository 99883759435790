import moment from "moment";
import Papa from "papaparse";
import { add } from "ionicons/icons";
import readXlsxFile, { Schema } from "read-excel-file";
import { createRef, useEffect, useState } from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";

import { BreedingAttempt } from "../../../models/breeding";
import BreedingRecordCard from "./BreedingCard/BreedingRecordCard";
import useBreedingService from "../../../services/useBreedingService";

interface BreedingProps {
  cowId: string;
}

interface BreedingExcelRow {
  cowId: string;
  firstAttemptDate: Date;
  firstAttemptSire: string;
  firstAttemptAiTech: string;
  secondAttemptDate: Date;
  secondAttemptSire: string;
  secondAttemptAiTech: string;
  thirdAttemptDate: Date;
  thirdAttemptSire: string;
  thirdAttemptAiTech: string;
}

export default function BreedingList({
  cowId,
}: BreedingProps): React.ReactElement {
  const ref = createRef<any>();
  const { getBreedingAttemptsSub, addBreedAttempt } = useBreedingService();
  const [breedingRecords, setBreedingRecords] = useState<BreedingAttempt[]>([]);

  useEffect(() => {
    getBreedingAttemptsSub(cowId, setBreedingRecords);
  }, [cowId, getBreedingAttemptsSub]);

  const onImport = async (e: any) => {
    console.log(e);
    if (e.target.value.includes(".csv")) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log("csv data", results.data);
        },
      });
    }

    if (e.target.value.includes(".xlsx") || e.target.value.includes(".xls")) {
      const schema: Schema<any> = {
        "Cow ID": {
          prop: "cowId",
          type: String,
        },
        "First Attempt Date": {
          prop: "firstAttemptDate",
          type: Date,
        },
        "First Attempt Sire": {
          prop: "firstAttemptSire",
          type: String,
        },
        "First Attempt AI Tech": {
          prop: "firstAttemptAiTech",
        },
        "Second Attempt Date": {
          prop: "secondAttemptDate",
          type: Date,
        },
        "Second Attempt Sire": {
          prop: "secondAttemptSire",
          type: String,
        },
        "Second Attempt AI Tech": {
          prop: "secondAttemptAiTech",
        },
        "Third Attempt Date": {
          prop: "thirdAttemptDate",
          type: Date,
        },
        "Third Attempt Sire": {
          prop: "thirdAttemptSire",
          type: String,
        },
        "Thirds Attempt AI Tech": {
          prop: "thirdAttemptAiTech",
        },
      };
      const data = await readXlsxFile<BreedingExcelRow>(e.target.files[0], {
        schema,
      });

      data.rows.forEach((row) => {
        const breedingAttempts: any[] = [];

        if (row.firstAttemptDate) {
          breedingAttempts.push({
            //id: uuidv4(),
            sireId: row.firstAttemptSire,
            attemptDate: moment(row.firstAttemptDate).format("MM/DD/YYYY"),
            aiTech: row.firstAttemptAiTech,
            cowId: row.cowId,
            breedingAttempts,
            gender: "",
            twins: false,
            pregnant: false,
            expectedDateBirth: "",
          });
        }

        if (row.secondAttemptDate) {
          breedingAttempts.push({
            //id: uuidv4(),
            sireId: row.secondAttemptSire,
            attemptDate: moment(row.secondAttemptDate).format("MM/DD/YYYY"),
            aiTech: row.secondAttemptAiTech,
            cowId: row.cowId,
            breedingAttempts,
            gender: "",
            twins: false,
            pregnant: false,
            expectedDateBirth: "",
          });
        }

        if (row.thirdAttemptDate) {
          breedingAttempts.push({
            //id: uuidv4(),
            sireId: row.thirdAttemptSire,
            attemptDate: moment(row.thirdAttemptDate).format("MM/DD/YYYY"),
            aiTech: row.thirdAttemptAiTech,
            cowId: row.cowId,
            breedingAttempts,
            gender: "",
            twins: false,
            pregnant: false,
            expectedDateBirth: "",
          });
        }

        // let breedingRecord: BreedingRecord = {
        //   cowId: row.cowId,
        //   breedingAttempts,
        //   gender: "",
        //   twins: false,
        //   pregnant: false,
        //   expectedDateBirth: "",
        // };

        // addBreed({
        //   ...breedingRecord,
        // });

        breedingAttempts.forEach((breedingAttempt) => {
          addBreedAttempt({
            ...breedingAttempt,
          });
        });
      });
    }
  };

  return (
    <>
      <input type="file" hidden ref={ref} onChange={onImport} />
      {breedingRecords.map((breedingRecord) => (
        <BreedingRecordCard
          key={breedingRecord.id}
          breedingRecord={breedingRecord}
        />
      ))}
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton routerLink={`/cows/${cowId}/add-breeding`}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      {/* <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton
          onClick={() => {
            ref.current.click();
          }}
        >
          <IonIcon icon={cloudUploadOutline}></IonIcon>
        </IonFabButton>
      </IonFab> */}
    </>
  );
}
