import {
  IonCard,
  IonButton,
  IonContent,
  IonTextarea,
  IonIcon,
  useIonAlert,
  useIonRouter,
  IonButtons,
} from "@ionic/react";
import React from "react";
import { Moment } from "moment";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import "./styles.css";

import { Note } from "../../../models";
import { getCurrentDate } from "../../../utils";
import Header from "../../../components/Header";
import TextField from "@mui/material/TextField";
import { useGoBack } from "../../../hooks/useGoBack";
import FormControl from "../../../components/FormControl/FormControl";
import useNotesService from "../../../services/useNotesService";

interface NoteFormProps {
  title: string;
  note?: Partial<Note>;
  onFormSubmit: (data: Note) => void;
}

export default function NoteForm({
  note,
  title,
  onFormSubmit,
}: NoteFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { deleteNote } = useNotesService();
  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<Note>({
    defaultValues: {
      text: note?.text || "",
      date: note?.date || getCurrentDate(),
    },
  });

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: "/notes",
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
    reset(data);
  });

  const onDeleteNote = (noteId: string) => {
    presentAlert({
      header: "Are you sure you want to delete the note?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            deleteNote(noteId);
            router.push(`/notes`, "none");
          },
        },
      ],
    });
  };

  return (
    <form onSubmit={onSubmit} className="NoteForm">
      <Header
        title={"Edit Note"}
        onGoBack={goBack}
        rightComponent={
          <IonButtons slot="end">
            {note && (
              <IonButton
                size={"small"}
                fill={"clear"}
                onClick={() => {
                  onDeleteNote(note?.id!);
                }}
              >
                <IonIcon src={"assets/trash.svg"} color={"danger"} />
              </IonButton>
            )}
            <div style={{ width: 15 }} />
            <IonButton
              fill="clear"
              type="submit"
              disabled={!isValid}
              style={{ color: "#4888AE" }}
            >
              Save
            </IonButton>
          </IonButtons>
        }
      />
      <IonContent>
        <IonCard>
          {/*<IonCardContent>*/}
          <FormControl label={"Date"}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <MobileDatePicker
                  closeOnSelect
                  value={field.value}
                  inputFormat="MM/DD/YYYY"
                  onChange={(e: Moment | null) => {
                    setValue("date", e?.format("MM/DD/YYYY"), {
                      shouldDirty: true,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size={"small"}
                      fullWidth
                      style={{ marginTop: 10 }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
          {/* <FormControl label={"Text"}> */}
          <div style={{ paddingInline: 20, color: "black" }}>
            <IonTextarea
              {...register("text", {
                required: "This is a required field",
                minLength: 1,
              })}
              placeholder="Text"
              onIonChange={(e: any) =>
                setValue("text", e.target.value as string, {
                  shouldDirty: true,
                })
              }
              rows={20}
            />
          </div>
          {/* </FormControl> */}
        </IonCard>
      </IonContent>
    </form>
  );
}
