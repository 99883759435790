import { useParams } from "react-router";
import { IonPage, useIonRouter } from "@ionic/react";

import { Vaccine } from "../../../models";
import VaccineForm from "../../../components/Vaccines/VaccineForm";
import useVaccinesService from "../../../services/useVaccinesService";
import useCalvesService from "../../../services/useCalvesService";

type RouteParams = { bookId: string; calfId: string };

export default function AddCalfVaccine(): React.ReactElement {
  const router = useIonRouter();
  const { updateCalfLastVaccineDate } = useCalvesService();
  const { addVaccine } = useVaccinesService();
  const { calfId, bookId } = useParams<RouteParams>();

  const onAddCalfVaccine = async (data: Vaccine) => {
    addVaccine({
      ...data,
      calfId,
    });
    // updateCalf(calfId, { lastVaccineDate: data.date });
    updateCalfLastVaccineDate(calfId, data.date);
    router.push(`/books/${bookId}/calves/${calfId}`);
  };

  return (
    <IonPage>
      <VaccineForm
        title="Add Vaccine"
        onFormSubmit={onAddCalfVaccine}
        backLink={`/books/${bookId}/calves/${calfId}`}
      />
    </IonPage>
  );
}
