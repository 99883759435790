import { FirestoreDataConverter } from "firebase/firestore";

import { OrgData } from "../../models/organization";

const organizationConverter: FirestoreDataConverter<OrgData> = {
  toFirestore: (organization) => ({
    companyName: organization.companyName,
    companyAddress: organization.companyAddress,
    companyZipCode: organization.companyZipCode,
    companyCity: organization.companyCity,
    companyState: organization.companyState,
    cowName: organization.cowName,
    calfName: organization.calfName,
    createdAt: organization.createdAt,
    updatedAt: organization.updatedAt,
    trialDays: organization.trialDays,
    expireDate: organization.expireDate,
    phoneNumber: organization.phoneNumber,
    email: organization.email,
    firstName: organization.firstName,
    lastName: organization.lastName,
    lastLogin: organization.lastLogin,
    lastVisitedBook: organization.lastVisitedBook,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      email: data.email,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
      companyAddress: data.companyAddress,
      companyZipCode: data.companyZipCode,
      companyCity: data.companyCity,
      companyState: data.companyState,
      cowName: data.cowName,
      calfName: data.calfName,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      trialDays: data.trialDays,
      expireDate: data.expireDate,
      firstName: data.firstName,
      lastName: data.lastName,
      trialExpireDate: data.trialExpireDate,
      lastVisitedBook: data.lastVisitedBook,
      versionNumberLastCheckDateIos: data.versionNumberLastCheckDateIos,
      versionNumberLastCheckDateAndroid: data.versionNumberLastCheckDateAndroid,
    };
  },
};

export default organizationConverter;
