import moment from "moment";
import Papa from "papaparse";
import { createRef, useRef, useState } from "react";
import { Network } from "@capacitor/network";
import readXlsxFile, { Schema } from "read-excel-file";
import { IonButton, IonContent, IonModal } from "@ionic/react";

import Header from "../Header";
import useBreedingService from "../../services/useBreedingService";

interface BreedingImportConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface BreedingExcelRow {
  cowId: string;
  firstAttemptDate: Date;
  firstAttemptSire: string;
  firstAttemptAiTech: string;
  secondAttemptDate: Date;
  secondAttemptSire: string;
  secondAttemptAiTech: string;
  thirdAttemptDate: Date;
  thirdAttemptSire: string;
  thirdAttemptAiTech: string;
}

export default function BreedingImportConfirmationModal({
  isOpen,
  onClose,
}: BreedingImportConfirmationModalProps) {
  const ref = createRef<any>();
  const { addBreedAttempt } = useBreedingService();
  const modal = useRef<HTMLIonModalElement>(null);
  const errorModal = useRef<HTMLIonModalElement>(null);
  const [error, setError] = useState<string | undefined>();

  const onImport = async (e: any) => {
    if (e.target.value.includes(".csv")) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log("csv data", results.data);
        },
      });
    }

    if (e.target.value.includes(".xlsx") || e.target.value.includes(".xls")) {
      const schema: Schema<any> = {
        "Cow ID": {
          prop: "cowId",
          type: String,
        },
        "First Attempt Date": {
          prop: "firstAttemptDate",
          type: Date,
        },
        "First Attempt Sire": {
          prop: "firstAttemptSire",
          type: String,
        },
        "First Attempt AI Tech": {
          prop: "firstAttemptAiTech",
        },
        "Second Attempt Date": {
          prop: "secondAttemptDate",
          type: Date,
        },
        "Second Attempt Sire": {
          prop: "secondAttemptSire",
          type: String,
        },
        "Second Attempt AI Tech": {
          prop: "secondAttemptAiTech",
        },
        "Third Attempt Date": {
          prop: "thirdAttemptDate",
          type: Date,
        },
        "Third Attempt Sire": {
          prop: "thirdAttemptSire",
          type: String,
        },
        "Thirds Attempt AI Tech": {
          prop: "thirdAttemptAiTech",
        },
      };
      const data = await readXlsxFile<BreedingExcelRow>(e.target.files[0], {
        schema,
      });

      try {
        const networkStatus = await Network.getStatus();

        if (!networkStatus.connected) {
          throw new Error("You need to be online in order to import the file.");
        }

        //const errors: string[] = [];

        const breedingAttempts: any[] = [];

        for (let i = 0; i < data.rows.length; i++) {
          const row = data.rows[i];

          if (
            !row.cowId ||
            !row.firstAttemptDate ||
            !row.firstAttemptSire ||
            !row.firstAttemptAiTech
          ) {
            setError(
              `Row ${
                i + 1
              } has no Cow ID, First Attempt Date, First Attempt Sire or First Attempt AI Tech`
            );
            return;
          }

          if (row.firstAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.firstAttemptSire,
              attemptDate: moment(row.firstAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.firstAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }

          if (row.secondAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.secondAttemptSire,
              attemptDate: moment(row.secondAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.secondAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }

          if (row.thirdAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.thirdAttemptSire,
              attemptDate: moment(row.thirdAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.thirdAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }
        }

        data.rows.forEach((row, idx) => {
          // if (
          //   !row.cowId ||
          //   !row.firstAttemptDate ||
          //   !row.firstAttemptSire ||
          //   !row.firstAttemptAiTech
          // ) {
          //   alert(
          //     `Row ${
          //       idx + 1
          //     } has no Cow ID, First Attempt Date, First Attempt Sire or First Attempt AI Tech`
          //   );
          //   return;
          // }
          // if (row.firstAttemptDate) {
          //   breedingAttempts.push({
          //     //id: uuidv4(),
          //     sireId: row.firstAttemptSire,
          //     attemptDate: moment(row.firstAttemptDate).format("MM/DD/YYYY"),
          //     aiTech: row.firstAttemptAiTech,
          //     cowId: row.cowId,
          //     breedingAttempts,
          //     gender: "",
          //     twins: false,
          //     pregnant: false,
          //     expectedDateBirth: "",
          //   });
          // }
          // if (row.secondAttemptDate) {
          //   breedingAttempts.push({
          //     //id: uuidv4(),
          //     sireId: row.secondAttemptSire,
          //     attemptDate: moment(row.secondAttemptDate).format("MM/DD/YYYY"),
          //     aiTech: row.secondAttemptAiTech,
          //     cowId: row.cowId,
          //     breedingAttempts,
          //     gender: "",
          //     twins: false,
          //     pregnant: false,
          //     expectedDateBirth: "",
          //   });
          // }
          // if (row.thirdAttemptDate) {
          //   breedingAttempts.push({
          //     //id: uuidv4(),
          //     sireId: row.thirdAttemptSire,
          //     attemptDate: moment(row.thirdAttemptDate).format("MM/DD/YYYY"),
          //     aiTech: row.thirdAttemptAiTech,
          //     cowId: row.cowId,
          //     breedingAttempts,
          //     gender: "",
          //     twins: false,
          //     pregnant: false,
          //     expectedDateBirth: "",
          //   });
          // }
        });

        breedingAttempts.forEach((breedingAttempt) => {
          addBreedAttempt({
            ...breedingAttempt,
          });
        });

        onClose();
      } catch (err: any) {
        console.log("err", err);
        alert("Something went wrong!");
      }
    }
  };

  return (
    <IonModal ref={modal} isOpen={isOpen} onWillDismiss={(e) => onClose()}>
      {error && (
        <IonModal
          ref={errorModal}
          isOpen={!!error}
          onWillDismiss={() => setError(undefined)}
        >
          <Header
            title="Something went wrong"
            rightComponent={
              <IonButton size="small" onClick={() => setError(undefined)}>
                Close
              </IonButton>
            }
          />
          <IonContent>
            <div
              style={{
                padding: 25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  width: "75%",
                  textAlign: "center",
                }}
              >
                {error}
              </span>
            </div>
          </IonContent>
        </IonModal>
      )}
      <Header
        title="Breeding import"
        rightComponent={
          <IonButton size="small" onClick={onClose}>
            Cancel
          </IonButton>
        }
      />
      <IonContent>
        <div
          style={{
            padding: 10,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            You are about to import an .xlsx file with breeding record attempts.
            Please make sure the file containes the following columns, with the
            exact name mentioned below:
          </span>
          <ul>
            <li>Cow ID</li>
            <li>First Attempt Date</li>
            <li>First Attempt Sire</li>
            <li>First Attempt AI Tech</li>
            <li>Second Attempt Date</li>
            <li>Second Attempt Sire</li>
            <li>Second Attempt AI Tech</li>
            <li>Third Attempt Date</li>
            <li>Third Attempt Sire</li>
            <li>Third Attempt AI Tech</li>
          </ul>
          <input
            type="file"
            accept=".xls,.xlsx"
            hidden
            ref={ref}
            onChange={onImport}
          />
          <IonButton
            style={{ width: "100%", marginTop: "auto" }}
            size="small"
            onClick={() => {
              ref.current.click();
            }}
          >
            Upload Breeding File
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
}
