import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";

import { Book } from "../../../models";

interface BooksSelectionProps {
  books: Book[];
  selectedBooks: string[];
  onBookSelected: (bookId: string) => void;
}

export default function BooksSelection({
  books,
  selectedBooks,
  onBookSelected,
}: BooksSelectionProps): React.ReactElement {
  return (
    <IonList>
      <IonItem>
        <IonSelect
          multiple
          okText="Add Book"
          placeholder="Books"
          value={selectedBooks.join(",")}
          onIonChange={(e: any) => onBookSelected(e.detail.value)}
        >
          {books.map((book) => (
            <IonSelectOption
              key={book.id}
              value={book.id}
              disabled={selectedBooks.includes(book.id)}
            >
              {book.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </IonList>
  );
}
