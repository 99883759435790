import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowUpOutline, arrowDownOutline } from "ionicons/icons";
import { useRef } from "react";

import { useBookSearch } from "./BookSearchProvider";

interface SortModalProps {
  isOpen: boolean;
  close: () => void;
}

const SORT_OPTIONS = [
  { label: "Cow Name", value: "cowName" },
  { label: "Calf Name", value: "calfName" },
  { label: "Calving Ease", value: "calvingEase" },
  { label: "Color", value: "color" },
  { label: "Tag Color", value: "tagColor" },
  { label: "Weight", value: "weight" },
  { label: "Gender", value: "gender" },
  { label: "Sire Name", value: "sireName" },
  { label: "Date of Birth", value: "dateOfBirth" },
  { label: "Date Added", value: "createdAt" },
];

export default function SortModal({
  close,
  isOpen,
}: SortModalProps): React.ReactElement {
  const modal = useRef<HTMLIonModalElement>(null);

  const { sortBy, onSortByChange, sortDirection, onSortDirectionChange } =
    useBookSearch();

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                modal.current?.dismiss();
                onSortDirectionChange();
              }}
            >
              <IonIcon
                icon={
                  sortDirection === "asc" ? arrowUpOutline : arrowDownOutline
                }
              />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Sort By</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup
            value={sortBy}
            onIonChange={(e: any) => {
              modal.current?.dismiss();
              onSortByChange(e.target.value);
            }}
            allowEmptySelection
          >
            {SORT_OPTIONS.map((option) => (
              <IonItem key={option.value}>
                {/* <IonLabel>{option.label}</IonLabel> */}
                <IonRadio value={option.value} justify="space-between">
                  {option.label}
                </IonRadio>
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonModal>
  );
}
