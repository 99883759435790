import { FirestoreDataConverter } from "firebase/firestore";

import { Pregnancy } from "../../models/breeding";

const pregnancyConverter: FirestoreDataConverter<Pregnancy> = {
  toFirestore: (pregnancy) => ({
    cowId: pregnancy.cowId,
    pregnant: pregnancy.pregnant,
    twins: pregnancy.twins,
    expectedDateBirth: pregnancy.expectedDateBirth,
    gender: pregnancy.gender,
    createdAt: pregnancy.createdAt,
    updatedAt: pregnancy.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      cowId: data.cowId,
      pregnant: data.pregnant,
      twins: data.twins,
      expectedDateBirth: data.expectedDateBirth,
      gender: data.gender,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default pregnancyConverter;
