import { useEffect, useState } from "react";
import useSiresService from "../../../../services/useSiresService";

import { Sire } from "../../../../models";

export function useBreedingForm() {
  const { getSires } = useSiresService();
  const [sires, setSires] = useState<Sire[]>([]);

  useEffect(() => {
    async function fetchSires() {
      const sires = await getSires();
      setSires(
        sires.sort((sire1, sire2) => {
          if (sire1.sireName.toLowerCase() < sire2.sireName.toLowerCase()) {
            return -1;
          }
          if (sire1.sireName.toLowerCase() > sire2.sireName.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    }

    fetchSires();
  }, [getSires]);

  const refetchSires = async () => {
    const sires = await getSires();
    setSires(
      sires.sort((sire1, sire2) => {
        if (sire1.sireName.toLowerCase() < sire2.sireName.toLowerCase()) {
          return -1;
        }
        if (sire1.sireName.toLowerCase() > sire2.sireName.toLowerCase()) {
          return 1;
        }
        return 0;
      })
    );
  };

  return {
    sires,
    refetchSires,
  };
}
