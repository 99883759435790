import { add } from "ionicons/icons";
import { useEffect, useState } from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";

import PregnancyCard from "./PregnancyCard";
import { Pregnancy } from "../../../models/breeding";
import useBreedingService from "../../../services/useBreedingService";

interface PregnancyProps {
  cowId: string;
}

export default function PregnancyList({
  cowId,
}: PregnancyProps): React.ReactElement {
  const { getPregnanciesSub } = useBreedingService();
  const [pregnancyRecords, setPregnancyRecords] = useState<Pregnancy[]>([]);

  useEffect(() => {
    // const fetchPregnancies = async () => {
    //   const pregnacies = await getPregnancies(cowId);
    //   setPregnancyRecords(pregnacies);
    // };

    // fetchPregnancies();
    getPregnanciesSub(cowId, setPregnancyRecords);
  }, [cowId, getPregnanciesSub]);
  console.log(pregnancyRecords);

  return (
    <>
      {pregnancyRecords
        //.filter((pregnancyRecords) => pregnancyRecords.pregnant)
        .map((pregnancyRecord) => (
          <PregnancyCard
            key={pregnancyRecord.id}
            pregnancyRecord={pregnancyRecord}
          />
        ))}

      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton routerLink={`/cows/${cowId}/add-pregnancy`}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
    </>
  );
}
