import { FirestoreDataConverter } from "firebase/firestore";

import { BreedingAttempt } from "../../models/breeding";

const breedingAttemptConverter: FirestoreDataConverter<BreedingAttempt> = {
  toFirestore: (breed) => ({
    cowId: breed.cowId,
    sireId: breed.sireId,
    aiTech: breed.aiTech,
    attemptDate: breed.attemptDate,
    createdAt: breed.createdAt,
    updatedAt: breed.updatedAt,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      cowId: data.cowId,
      sireId: data.sireId,
      aiTech: data.aiTech,
      attemptDate: data.attemptDate,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  },
};

export default breedingAttemptConverter;
