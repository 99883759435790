import { IonCard, IonInput } from "@ionic/react";
import { Controller, useFormContext } from "react-hook-form";

import "./styles.css";

import { Cow } from "../../../models";
import { useLocations } from "../../../hooks/useLocations";
import AutoComplete from "../../../components/AutoComplete";
import FormControl from "../../../components/FormControl/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";

interface FormProps {
  onFormSubmit: (data: Cow) => void;
}

export default function Form({ onFormSubmit }: FormProps): React.ReactElement {
  const { locations, insertNewLocation } = useLocations();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext<Cow>();

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
  });

  return (
    <form onSubmit={onSubmit} className={"CowForm"}>
      <IonCard>
        <FormControl
          label={"Cow ID"}
          labelWidth={"120px"}
          required
          error={!!errors.cowName}
        >
          <IonInput
            {...register("cowName", {
              required: "This is a required field",
              minLength: 1,
            })}
            placeholder="Cow ID"
            onIonChange={(e: any) =>
              setValue("cowName", e.target.value as string, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        </FormControl>
        <FormControl label={"Date of Purchase"} labelWidth={"120px"}>
          <Controller
            name="dateOfPurchase"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("dateOfPurchase", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl label={"Purchase Price $"} labelWidth={"120px"}>
          <IonInput
            {...register("purchasePrice", {
              valueAsNumber: true,
            })}
            type="number"
            placeholder="Purchase Price"
            onIonChange={(e: any) =>
              setValue("purchasePrice", e.target.value as number, {
                shouldDirty: true,
              })
            }
          />
        </FormControl>
        <FormControl label={"Location"} labelWidth={"120px"}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <AutoComplete
                value={field.value}
                onValueChange={(value) => {
                  setValue("location", value.label, { shouldDirty: true });
                }}
                onAddNewValue={(value) => {
                  insertNewLocation(value.label);
                }}
                inputLabel="Select or create a location"
                options={locations.map((location) => ({
                  label: location,
                  id: location,
                }))}
              />
            )}
          />
        </FormControl>
      </IonCard>
    </form>
  );
}
