import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

interface OptionType {
  id: string;
  label: string;
  inputValue?: string;
}

interface AutoCompleteProps {
  value: string;
  inputLabel: string;
  options: OptionType[];
  onValueChange: (value: OptionType) => void;
  onAddNewValue: (value: OptionType) => void;
}

const filter = createFilterOptions<OptionType>();

export default function AutoComplete({
  value,
  options,
  inputLabel,
  onValueChange,
  onAddNewValue,
}: AutoCompleteProps) {
  const [currValue, setCurrValue] = useState<OptionType | null>(null);
  useEffect(() => {
    const option = options.find((option) => option.id === value);
    if (option) {
      setCurrValue(option);
    } else {
      setCurrValue(null);
    }
  }, [value, options]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      value={currValue}
      options={options}
      style={{ marginTop: 10 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          const addedValue = {
            id: uuidv4(),
            label: newValue.inputValue,
          };
          setCurrValue(addedValue);
          onValueChange(addedValue);
          onAddNewValue(addedValue);
        } else {
          setCurrValue(newValue);
          onValueChange(newValue!);
        }
      }}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const filtered = filter(options, params);
        const isExisting = options.some(
          (option) => inputValue === option.label
        );

        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            id: "new value",
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
    />
  );
}
