import {
  IonFab,
  IonIcon,
  IonPage,
  IonContent,
  IonFabButton,
  IonSearchbar,
  IonButton,
} from "@ionic/react";
import moment from "moment";
import Papa from "papaparse";
//import { v4 as uuidv4 } from "uuid";
import { add } from "ionicons/icons";
import { Virtuoso } from "react-virtuoso";
import readXlsxFile, { Schema } from "read-excel-file";
import { Network } from "@capacitor/network";
import { createRef, useEffect, useState } from "react";

import "./styles.css";

import CowCard from "./CowCard";
import { Cow } from "../../../models";
import useCowsService from "../../../services/useCowsService";
import Header from "../../../components/Header";
import useBreedingService from "../../../services/useBreedingService";
import ErrorModal from "../../../components/ErrorModal";
import BreedingImportConfirmationModal from "../../../components/ConfirmationModal";

interface BreedingExcelRow {
  cowId: string;
  firstAttemptDate: Date;
  firstAttemptSire: string;
  firstAttemptAiTech: string;
  secondAttemptDate: Date;
  secondAttemptSire: string;
  secondAttemptAiTech: string;
  thirdAttemptDate: Date;
  thirdAttemptSire: string;
  thirdAttemptAiTech: string;
}

export default function CowsList(): React.ReactElement {
  const ref = createRef<any>();
  const { addBreedAttempt } = useBreedingService();
  const { getCowsSub } = useCowsService();

  const [cows, setCows] = useState<Cow[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [importError, setImportError] = useState("");
  const [breedingImportModalIsOpen, setBreedingImportModalIsOpen] =
    useState(false);

  useEffect(() => {
    getCowsSub((cows) => {
      setCows(
        cows.sort((cow1, cow2) => {
          if (cow1.cowName?.toLowerCase() < cow2.cowName?.toLowerCase()) {
            return -1;
          }
          if (cow1.cowName?.toLowerCase() > cow2.cowName?.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, [getCowsSub]);

  const filterCows = (cows: Cow[]) => {
    if (searchValue && searchValue.length > 0) {
      return cows.filter((cow) => {
        return cow.cowName?.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    return cows;
  };

  const onImport = async (e: any) => {
    if (e.target.value.includes(".csv")) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log("csv data", results.data);
        },
      });
    }

    if (e.target.value.includes(".xlsx") || e.target.value.includes(".xls")) {
      const schema: Schema<any> = {
        "Cow ID": {
          prop: "cowId",
          type: String,
        },
        "First Attempt Date": {
          prop: "firstAttemptDate",
          type: Date,
        },
        "First Attempt Sire": {
          prop: "firstAttemptSire",
          type: String,
        },
        "First Attempt AI Tech": {
          prop: "firstAttemptAiTech",
        },
        "Second Attempt Date": {
          prop: "secondAttemptDate",
          type: Date,
        },
        "Second Attempt Sire": {
          prop: "secondAttemptSire",
          type: String,
        },
        "Second Attempt AI Tech": {
          prop: "secondAttemptAiTech",
        },
        "Third Attempt Date": {
          prop: "thirdAttemptDate",
          type: Date,
        },
        "Third Attempt Sire": {
          prop: "thirdAttemptSire",
          type: String,
        },
        "Thirds Attempt AI Tech": {
          prop: "thirdAttemptAiTech",
        },
      };
      const data = await readXlsxFile<BreedingExcelRow>(e.target.files[0], {
        schema,
      });

      try {
        const networkStatus = await Network.getStatus();

        if (!networkStatus.connected) {
          throw new Error("You need to be online in order to import the file.");
        }

        //const errors: string[] = [];

        data.rows.forEach((row) => {
          const breedingAttempts: any[] = [];

          if (row.firstAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.firstAttemptSire,
              attemptDate: moment(row.firstAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.firstAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }

          if (row.secondAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.secondAttemptSire,
              attemptDate: moment(row.secondAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.secondAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }

          if (row.thirdAttemptDate) {
            breedingAttempts.push({
              //id: uuidv4(),
              sireId: row.thirdAttemptSire,
              attemptDate: moment(row.thirdAttemptDate).format("MM/DD/YYYY"),
              aiTech: row.thirdAttemptAiTech,
              cowId: row.cowId,
              breedingAttempts,
              gender: "",
              twins: false,
              pregnant: false,
              expectedDateBirth: "",
            });
          }

          // let breedingRecord: BreedingRecord = {
          //   cowId: row.cowId,
          //   breedingAttempts,
          //   gender: "",
          //   twins: false,
          //   pregnant: false,
          //   expectedDateBirth: "",
          // };

          // addBreed({
          //   ...breedingRecord,
          // });

          breedingAttempts.forEach((breedingAttempt) => {
            addBreedAttempt({
              ...breedingAttempt,
            });
          });
        });
      } catch (err: any) {
        console.log("err", err);
        setImportError(err.message);
      }
    }
  };

  return (
    <IonPage>
      {breedingImportModalIsOpen ? (
        <BreedingImportConfirmationModal
          isOpen={breedingImportModalIsOpen}
          onClose={() => setBreedingImportModalIsOpen(false)}
        />
      ) : null}
      <Header
        title={"Cows"}
        rightComponent={
          <>
            <input type="file" hidden ref={ref} onChange={onImport} />
            <IonButton
              size="small"
              onClick={() => {
                //ref.current.click();
                setBreedingImportModalIsOpen(true);
              }}
            >
              Import Breeding
            </IonButton>
          </>
        }
      >
        <Header.SubHeader>
          <IonSearchbar
            value={searchValue}
            onIonInput={(e: any) => {
              setSearchValue(e.target.value || "");
            }}
            className="ion-padding-horizontal"
          />
        </Header.SubHeader>
      </Header>
      <IonContent className={"CowsList"} scrollY={false}>
        <div>
          <div className={"CowsListHeader"}>
            <span>Cow name</span>
            <span>Location</span>
          </div>
          <Virtuoso
            data={filterCows(cows)}
            itemContent={(index, cow: Cow) => (
              <CowCard key={cow.id} cow={cow} />
            )}
          />
        </div>
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton routerLink="/add-cow">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
      {importError ? (
        <ErrorModal
          title="File import error"
          errorMessage={importError}
          onClose={() => setImportError("")}
        >
          {importError.split(",").length > 1 ? (
            <div>
              {importError.split(",").map((error) => (
                <p key={error}>{error}</p>
              ))}
            </div>
          ) : undefined}
        </ErrorModal>
      ) : null}
    </IonPage>
  );
}
