import { useRef } from "react";
import { IonButton, IonContent, IonModal } from "@ionic/react";
import Header from "../Header";

interface ErrorModalProps {
  children?: any;
  title?: string;
  onClose: () => void;
  errorMessage?: string;
}

export default function ErrorModal({
  onClose,
  children,
  errorMessage,
  title = "An error has occured",
}: ErrorModalProps) {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal
      isOpen={!!errorMessage}
      ref={modal}
      onWillDismiss={(e) => onClose()}
    >
      <Header
        title={title}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => modal.current?.dismiss()}
          >
            Close
          </IonButton>
        }
      />
      <IonContent>
        <div
          style={{
            padding: 20,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {errorMessage && !children && errorMessage}
          {errorMessage && children && children}
        </div>
      </IonContent>
    </IonModal>
  );
}
