import { useParams } from "react-router";
import { useEffect, useState } from "react";
import {
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";

import SireForm from "../SireForm";
import { Calf, Sire } from "../../../models";
import useSiresService from "../../../services/useSiresService";
import CalfModal from "../../../components/CalfModal";

type RouteParams = { sireId: string };

export default function EditSire(): React.ReactElement {
  const router = useIonRouter();
  const { sireId } = useParams<RouteParams>();
  const { getSire, updateSire, getSiresCalvesSub } = useSiresService();

  const [sire, setSire] = useState<Sire>();
  const [calves, setCalves] = useState<Calf[]>([]);
  const [selectedCalf, setSelectedCalf] = useState<Calf | null>(null);

  useEffect(() => {
    async function fetchSire() {
      const sire = await getSire(sireId);
      setSire(sire);
    }

    fetchSire();
    getSiresCalvesSub(sireId!, setCalves);
  }, [sireId, getSire, getSiresCalvesSub]);

  const onEditSire = async (data: Sire) => {
    updateSire(sireId, data);
    router.push(`/sires`);
  };

  const calf: Calf = {
    ...selectedCalf!,
    sireName: sire?.sireName || "",
  };

  const calculateAverageWeight = (
    calves: Calf[],
    weightType: "weight" | "weaningWeight"
  ) => {
    const filteredCalves = calves.filter((calf) => !isNaN(calf[weightType]));
    let calvesAmount = 0;
    let calvesTotalWeight = 0;
    filteredCalves.forEach((calf) => {
      if (calf[weightType]) {
        calvesAmount += 1;
        calvesTotalWeight += calf[weightType];
      }
    });

    return calvesTotalWeight / calvesAmount;
  };

  const avgBirthWeight = calculateAverageWeight(calves, "weight");
  const avgWeaningWeight = calculateAverageWeight(calves, "weaningWeight");

  return (
    <IonPage>
      {sire && (
        <IonContent>
          <SireForm
            title={`Edit ${sire.sireName}`}
            sire={sire}
            onFormSubmit={onEditSire}
          />
          <IonItem>
            <IonLabel>Avg Birth Weight</IonLabel>
            <IonLabel>{isNaN(avgBirthWeight) ? "-" : avgBirthWeight}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Avg Weaning Weight</IonLabel>
            <IonLabel>
              {isNaN(avgWeaningWeight) ? "-" : avgWeaningWeight}
            </IonLabel>
          </IonItem>
          <IonCard>
            <IonList>
              <IonListHeader>
                {/* <IonLabel>{`${sire?.sireName} ’s calves`}</IonLabel> */}
                <IonLabel>Calves</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel>Calf Name</IonLabel>
                <IonLabel>Cow Name</IonLabel>
              </IonItem>
              {calves.map((calf) => (
                <IonItem key={calf.id} onClick={() => setSelectedCalf(calf)}>
                  <IonIcon icon={chevronForward} slot="end" />
                  <IonLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{calf.calfName}</span>
                    </div>
                  </IonLabel>
                  <IonLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{calf.cowName}</span>
                    </div>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonCard>
        </IonContent>
      )}
      {selectedCalf && (
        <CalfModal calf={calf} onClose={() => setSelectedCalf(null)} />
      )}
    </IonPage>
  );
}
