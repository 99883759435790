import {
  IonCard,
  IonInput,
  IonSelect,
  IonToggle,
  IonTextarea,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import { Moment } from "moment";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";

import "./styles.css";

import { Calf } from "../../../models";
import SliderTwinsForm from "./TwinsForm";
import { toUpperCase } from "../../../utils";
import { useCalfForm } from "./useCalfForm";
import { useColors } from "../../../hooks/useColors";
import AutoComplete from "../../../components/AutoComplete";
import FormControl from "../../../components/FormControl/FormControl";
import useSiresService from "../../../services/useSiresService";
import useCowsService from "../../../services/useCowsService";
import useBreedingService from "../../../services/useBreedingService";

interface FormProps {
  isNew: boolean;
  twinFormApi: UseFormReturn<Calf>;
  selectedGender: string;
  setSelectedGender: (value: string) => void;
}

export default function Form({
  isNew,
  twinFormApi,
  selectedGender,
  setSelectedGender,
}: FormProps): React.ReactElement {
  const formApi = useFormContext<Calf>();
  const { getCowByName } = useCowsService();
  const { addQuickSire } = useSiresService();
  const { colors, insertNewColor } = useColors();
  const { getLastAttempt } = useBreedingService();
  const { books, sires, setSires } = useCalfForm();

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = formApi;

  const hasTwin = selectedGender === "Twin";

  const onCowNameBlur = async () => {
    if (isNew) {
      const { cowName } = getValues();

      if (cowName) {
        const cow = await getCowByName(cowName);

        if (cow) {
          const attempt = await getLastAttempt(
            cow.id,
            new Date().getFullYear()
          );
          if (attempt) {
            setValue("sireId", attempt.sireId);
          }
        }
      }
    }
  };

  return (
    <form onSubmit={(e) => console.log(e)} className={"CalfForm"}>
      <IonCard>
        <FormControl label={"Cow ID"} required error={!!errors.cowName}>
          <IonInput
            {...register("cowName", {
              required: "This is a required field",
              minLength: 1,
            })}
            placeholder="Cow ID"
            onIonChange={(e: any) => {
              setValue("cowName", toUpperCase(e.target.value as string), {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue("calfName", toUpperCase(e.target.value as string), {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            onBlur={onCowNameBlur}
          />
        </FormControl>
        {!hasTwin && (
          <FormControl label={"Calf ID"} required error={!!errors.calfName}>
            <IonInput
              {...register("calfName", {
                required: "This is a required field",
                minLength: 1,
              })}
              placeholder="Calf ID"
              onIonChange={(e: any) => {
                setValue("calfName", toUpperCase(e.target.value as string), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </FormControl>
        )}
        <FormControl label={`Gender${getValues().isTwin ? " (Twin)" : ""}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <Controller
                control={control}
                render={({ field }) => (
                  <IonSelect
                    placeholder="Select"
                    value={
                      isNew || !getValues().isTwin
                        ? selectedGender
                        : field.value
                    }
                    onIonChange={(e: any) => {
                      if (e.target.value !== "Twin") {
                        setValue("gender", e.target.value, {
                          shouldDirty: true,
                        });
                      }

                      setSelectedGender(e.target.value);
                    }}
                  >
                    {["Bull", "Heifer", "Steer"].map((gender) => (
                      <IonSelectOption key={gender} value={gender}>
                        {gender}
                      </IonSelectOption>
                    ))}
                    {(isNew || !getValues().isTwin) && (
                      <IonSelectOption key={"Twin"} value={"Twin"}>
                        Twin
                      </IonSelectOption>
                    )}
                  </IonSelect>
                )}
                name={"gender"}
              />
            </div>
            <IonButton
              fill={"clear"}
              size={"small"}
              onClick={() => {
                setValue<any>("gender", undefined, {
                  shouldDirty: true,
                });
                setSelectedGender("");
              }}
            >
              Clear
            </IonButton>
          </div>
        </FormControl>

        {hasTwin && (
          <SliderTwinsForm
            colors={colors}
            twinOneFormApi={formApi}
            twinTwoFormApi={twinFormApi}
            insertNewColor={insertNewColor}
          />
        )}

        {!hasTwin && (
          <FormControl label={"Weight"}>
            <IonInput
              {...register("weight", {
                valueAsNumber: true,
              })}
              type="number"
              placeholder="Weight"
              onIonChange={(e: any) =>
                setValue("weight", e.target.value as number, {
                  shouldDirty: true,
                })
              }
            />
          </FormControl>
        )}

        <FormControl label={"Sire ID"}>
          <Controller
            name="sireId"
            control={control}
            render={({ field }) => (
              <AutoComplete
                value={field.value}
                onValueChange={(value) => {
                  setValue("sireId", value.id, { shouldDirty: true });
                }}
                onAddNewValue={(value) => {
                  addQuickSire(value.id, {
                    sireName: value.label,
                  });
                  setSires([
                    ...sires,
                    {
                      id: value.id,
                      sireName: value.label,
                    },
                  ]);
                }}
                inputLabel="Select or create a sire"
                options={sires.map((sire) => ({
                  id: sire.id,
                  label: sire.sireName,
                }))}
              />
            )}
          />
        </FormControl>

        {!hasTwin && (
          <FormControl label={"Color"}>
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <AutoComplete
                  value={field.value}
                  onValueChange={(value) => {
                    setValue("color", value.label, { shouldDirty: true });
                  }}
                  onAddNewValue={(value) => {
                    insertNewColor(value.label);
                  }}
                  inputLabel="Select or create a color"
                  options={colors.map((color) => ({
                    label: color,
                    id: color,
                  }))}
                />
              )}
            />
          </FormControl>
        )}

        <FormControl label={"Calving Ease"}>
          <IonInput
            {...register("calvinEase", {
              minLength: 1,
            })}
            placeholder="Calving Ease"
            onIonChange={(e: any) =>
              setValue("calvinEase", e.target.value as string, {
                shouldDirty: true,
              })
            }
          />
        </FormControl>

        <FormControl label={"Date of Birth"}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <MobileDatePicker
                closeOnSelect
                value={field.value || null}
                inputFormat="MM/DD/YYYY"
                onChange={(e: Moment | null) => {
                  setValue("dateOfBirth", e?.format("MM/DD/YYYY") || "", {
                    shouldDirty: true,
                  });
                }}
                componentsProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={"small"}
                    fullWidth
                    style={{ marginTop: 10 }}
                  />
                )}
              />
            )}
          />
        </FormControl>

        <FormControl label={"Udder Grade"}>
          <IonInput
            {...register("udderGrade", {
              minLength: 1,
            })}
            placeholder="Udder Grade"
            onIonChange={(e: any) =>
              setValue("udderGrade", e.target.value as string, {
                shouldDirty: true,
              })
            }
          />
        </FormControl>

        {!hasTwin && (
          <FormControl label={"Tag Color"}>
            <Controller
              name="tagColor"
              control={control}
              render={({ field }) => (
                <IonSelect
                  placeholder="Select"
                  value={field.value}
                  onIonChange={(e: any) => {
                    setValue("tagColor", e.target.value);
                  }}
                >
                  {[
                    "Black",
                    "Blue",
                    "Green",
                    "Orange",
                    "Pink",
                    "Purple",
                    "Red",
                    "Teal",
                    "White",
                    "Yellow",
                  ].map((tagColor) => (
                    <IonSelectOption key={tagColor} value={tagColor}>
                      {tagColor}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              )}
            />
          </FormControl>
        )}

        <FormControl label={"Comment"}>
          <IonTextarea
            {...register("comment")}
            placeholder="Comment"
            rows={3}
          />
        </FormControl>

        <FormControl label={"Book"}>
          <Controller
            name="bookId"
            control={control}
            render={({ field }) => (
              <IonSelect
                value={field.value}
                onIonChange={(e: any) => setValue("bookId", e.target.value)}
              >
                {books.map((book) => (
                  <IonSelectOption key={book.id} value={book.id}>
                    {book.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
        </FormControl>

        {!isNew && (
          <FormControl label={"Adopted By"} error={!!errors.adoptedBy}>
            <IonInput
              {...register("adoptedBy")}
              placeholder="Adopted By"
              onIonChange={(e: any) => {
                setValue("adoptedBy", toUpperCase(e.target.value as string), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </FormControl>
        )}

        {!isNew && (
          <>
            <FormControl label={"Dead"}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IonToggle
                  {...register("isDead")}
                  onIonChange={(e: any) => {
                    setValue("isDead", e.detail.checked);
                  }}
                  checked={getValues("isDead")}
                />
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#D41924",
                    marginLeft: 10,
                    borderRadius: 100,
                  }}
                />
              </div>
            </FormControl>
            <FormControl label={"Pairout"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IonToggle
                  {...register("isPairout")}
                  onIonChange={(e: any) => {
                    setValue("isPairout", e.detail.checked);
                  }}
                  checked={getValues("isPairout")}
                />
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#47E0E8",
                    marginLeft: 10,
                    borderRadius: 100,
                  }}
                />
              </div>
            </FormControl>
            <FormControl label={"Cull"}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IonToggle
                  {...register("isCull")}
                  onIonChange={(e: any) => {
                    setValue("isCull", e.detail.checked);
                  }}
                  checked={getValues("isCull")}
                />
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#F9B32F",
                    marginLeft: 10,
                    borderRadius: 100,
                  }}
                />
              </div>
            </FormControl>
          </>
        )}
      </IonCard>
    </form>
  );
}
