import {
  IonCard,
  IonLabel,
  IonTitle,
  IonCardHeader,
  IonCardContent,
} from "@ionic/react";
import moment from "moment";

import "./styles.css";

import { BreedingAttempt } from "../../../../models/breeding";

interface BreedingRecordCardProps {
  breedingRecord: BreedingAttempt;
}
export default function BreedingRecordCard({
  breedingRecord,
}: BreedingRecordCardProps): React.ReactElement {
  // useEffect(() => {
  //   fetchSire();

  //   async function fetchSire() {
  //     const sire = await getSire(breedingRecord.sireId);
  //     setSire(sire);
  //   }
  // }, [getSire, breedingRecord.sireId]);

  return (
    <IonCard
      routerLink={`/cows/${breedingRecord.cowId}/breedings/${breedingRecord.id}`}
    >
      <div style={{ marginBlock: 10, textAlign: "left" }}>
        <IonCardHeader>
          <IonTitle>{`${moment(
            breedingRecord.createdAt?.toDate()
          ).year()} Record`}</IonTitle>
        </IonCardHeader>
      </div>
      <IonCardContent>
        <IonLabel>
          Sire ID: <span>{breedingRecord?.sireId || ""}</span>
        </IonLabel>
        <IonLabel>
          Attempt Date: <span>{breedingRecord.attemptDate}</span>
        </IonLabel>
        <IonLabel>
          Ai Tech: <span>{breedingRecord.aiTech}</span>
        </IonLabel>
      </IonCardContent>
    </IonCard>
  );
}
