import { FirestoreDataConverter } from "firebase/firestore";

import { Vaccine } from "../../models";

const vaccineConverter: FirestoreDataConverter<Vaccine> = {
  toFirestore: (vaccine) => ({
    dose: vaccine.dose,
    date: vaccine.date,
    cowId: vaccine.cowId,
    calfId: vaccine.calfId,
    batchId: vaccine.batchId,
    comment: vaccine.comment,
    isBatch: vaccine.isBatch,
    shotName: vaccine.shotName,
    booksIds: vaccine.booksIds,
    isCowBatch: vaccine.isCowBatch,
    isBookBatch: vaccine.isBookBatch,
    createdAt: vaccine.createdAt,
    updatedAt: vaccine.updatedAt,
    status: vaccine.status,
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      dose: data.dose,
      date: data.date,
      cowId: data.cowId,
      calfId: data.calfId,
      batchId: data.batchId,
      comment: data.comment,
      isBatch: data.isBatch,
      booksIds: data.booksIds,
      shotName: data.shotName,
      isCowBatch: data.isCowBatch,
      isBookBatch: data.isBookBatch,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      status: data.status,
    };
  },
};

export default vaccineConverter;
