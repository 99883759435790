import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import { IonPage, useIonRouter } from "@ionic/react";

import CalfForm from "../CalfForm";
import { Calf } from "../../../models";
import useCowsService from "../../../services/useCowsService";
import useBooksService from "../../../services/useBooksService";
import useCalvesService from "../../../services/useCalvesService";

type RouteParams = { bookId: string };

export default function AddCalf(): React.ReactElement {
  const router = useIonRouter();
  const { upsertCow } = useCowsService();
  const { addCalf } = useCalvesService();
  const { bookId } = useParams<RouteParams>();
  const { updateCalvesCount } = useBooksService();

  const onAddCalf = async (data: Calf, twinData?: Calf) => {
    addNewCalf(data, twinData);
  };

  const addNewCalf = async (data: Calf, twinData?: Calf) => {
    try {
      let twinId = undefined;
      const calfId = uuidv4();

      upsertCow(data.cowName as string);

      //if (data.isTwin) twinId = uuidv4();
      if (twinData?.calfName) twinId = uuidv4();

      addCalf(calfId, {
        ...data,
        twinId,
        bookId,
        isTwin: !!twinId,
        dateOfBirth: data.dateOfBirth || "",
      });

      if (twinId) {
        addCalf(twinId, {
          ...(twinData as Calf),
          dateOfBirth: data.dateOfBirth || "",
          gender: data.gender,
          sireId: data.sireId,
          cowId: data.cowId,
          cowName: data.cowName,
          adoptedBy: data.adoptedBy,
          twinId: calfId,
          isTwin: true,
          bookId,
          comment: data.comment,
          calvinEase: data.calvinEase,
          udderGrade: data.udderGrade,
        });
      }

      updateCalvesCount(data.bookId);

      router.push(`/books/${bookId}`);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <IonPage>
      <CalfForm title="Add Calf" onFormSubmit={onAddCalf} />
    </IonPage>
  );
}
