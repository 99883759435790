import { useRef } from "react";
import { IonButton, IonContent, IonModal } from "@ionic/react";

import Header from "../Header";
import { Calf } from "../../models";
import CalfDetail from "./CalfDetail";

interface CalfModalProps {
  calf: Calf;
  onClose: () => void;
}

export default function CalfModal({
  calf,
  onClose,
}: CalfModalProps): React.ReactElement {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal isOpen={!!calf} ref={modal} onWillDismiss={(e) => onClose()}>
      <Header
        title={`Calf Details - ${calf.calfName}`}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => modal.current?.dismiss()}
          >
            Close
          </IonButton>
        }
      />
      <IonContent>
        <CalfDetail calf={calf} />
      </IonContent>
    </IonModal>
  );
}
