import { useRef } from "react";
import { IonModal, IonButton, IonContent, IonItem } from "@ionic/react";

import Header from "../../../../components/Header";

interface MoreModalProps {
  sireId: string;
  isOpen: boolean;
  close: () => void;
}

export default function MoreModal({
  sireId,
  isOpen,
  close,
}: MoreModalProps): React.ReactElement {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal isOpen={isOpen} ref={modal} onWillDismiss={(e) => close()}>
      <Header
        title={"More"}
        withBorder={false}
        rightComponent={
          <IonButton
            size={"small"}
            fill={"clear"}
            className="ion-text-uppercase ion-no-margin ion-no-padding"
            onClick={() => modal.current?.dismiss()}
          >
            Cancel
          </IonButton>
        }
      />
      <IonContent>
        <IonItem
          routerLink={`/sires/${sireId}/summary`}
          onClick={() => {
            modal.current?.dismiss();
          }}
        >
          Summary Report of Calves Born
        </IonItem>
      </IonContent>
    </IonModal>
  );
}
