import {
  IonCard,
  IonInput,
  IonButton,
  IonContent,
  IonButtons,
  IonIcon,
  useIonRouter,
  useIonAlert,
} from "@ionic/react";
import { Moment } from "moment/moment";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import "./styles.css";

import { Sire } from "../../../models";
import Header from "../../../components/Header";
import { useGoBack } from "../../../hooks/useGoBack";
import { capitalizeFirstLetter } from "../../../utils";
import { useLocations } from "../../../hooks/useLocations";
import AutoComplete from "../../../components/AutoComplete";
import FormControl from "../../../components/FormControl/FormControl";
import useSiresService from "../../../services/useSiresService";

interface SireFormProps {
  sire?: Sire;
  title: string;
  onFormSubmit: (data: Sire) => void;
}

export default function SireForm({
  sire,
  title,
  onFormSubmit,
}: SireFormProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { deleteSire } = useSiresService();
  const { locations, insertNewLocation } = useLocations();

  const {
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors, dirtyFields },
  } = useForm<Sire>({
    defaultValues: {
      sireName: sire?.sireName || "",
      cost: sire?.cost || undefined,
      dateOfPurchase: sire?.dateOfPurchase || undefined,
      location: sire?.location || "",
    },
  });

  const goBack = useGoBack({
    guardValue: isDirty,
    navigateTo: "/sires",
    routerDirection: "back",
    onOkGuardHandler: () => {
      reset();
    },
  });

  const onSubmit = handleSubmit((data) => {
    onFormSubmit(data);
    insertNewLocation(data.location);
    if (sire) {
      reset(data);
    } else {
      reset();
    }
  });

  const onDeleteSire = (sireId: string) => {
    presentAlert({
      header: "Are you sure you want to delete the sire?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            deleteSire(sireId);
            router.push(`/sires`, "none");
          },
        },
      ],
    });
  };

  return (
    <form onSubmit={onSubmit} className="SireForm">
      <Header
        title={title}
        onGoBack={goBack}
        rightComponent={
          <IonButtons>
            {sire && !isDirty && !(Object.keys(dirtyFields).length > 0) && (
              <IonButton
                size={"small"}
                fill={"clear"}
                onClick={() => {
                  onDeleteSire(sire.id!);
                }}
              >
                <IonIcon src={"assets/trash.svg"} color={"danger"} />
              </IonButton>
            )}
            <div style={{ width: 15 }} />
            {/* {isDirty && Object.keys(dirtyFields).length > 0 && ( */}
            <IonButton
              fill="clear"
              type="submit"
              disabled={!isValid}
              style={{ color: "#4888AE" }}
            >
              Save
            </IonButton>
            {/* )} */}
          </IonButtons>
        }
      />
      <IonContent>
        <IonCard>
          <FormControl
            label={"Sire ID"}
            labelWidth={"120px"}
            required
            error={!!errors.sireName}
          >
            <IonInput
              {...register("sireName", {
                required: "This is a required field",
                minLength: 1,
              })}
              placeholder="Sire ID"
              onIonChange={(e: any) =>
                setValue(
                  "sireName",
                  capitalizeFirstLetter(e.target.value as string),
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  }
                )
              }
            />
          </FormControl>
          <FormControl label={"Date of Purchase"} labelWidth={"120px"}>
            <Controller
              name="dateOfPurchase"
              control={control}
              render={({ field }) => (
                <MobileDatePicker
                  closeOnSelect
                  value={field.value || null}
                  inputFormat="MM/DD/YYYY"
                  onChange={(e: Moment | null) => {
                    setValue("dateOfPurchase", e?.format("MM/DD/YYYY") || "", {
                      shouldDirty: true,
                    });
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size={"small"}
                      fullWidth
                      style={{ marginTop: 10 }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <FormControl label={"Cost"} labelWidth={"120px"}>
            <IonInput
              {...register("cost", {
                valueAsNumber: true,
              })}
              type="number"
              placeholder="Cost"
            />
          </FormControl>
          <FormControl label={"Location"} labelWidth={"120px"}>
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <AutoComplete
                  value={field.value || ""}
                  onValueChange={(value) => {
                    setValue("location", value.label, { shouldDirty: true });
                  }}
                  onAddNewValue={(value) => {
                    insertNewLocation(value.label);
                  }}
                  inputLabel="Select or create a location"
                  options={locations.map((location) => ({
                    label: location,
                    id: location,
                  }))}
                />
              )}
            />
          </FormControl>
        </IonCard>
      </IonContent>
    </form>
  );
}
