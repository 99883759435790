import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";
import { chevronBackOutline } from "ionicons/icons";

import useBookReport from "./useBookReport";

type RouteParams = { bookId: string };

export default function BookReport(): React.ReactElement {
  const { bookId } = useParams<RouteParams>();
  const { report } = useBookReport(bookId);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              fill="clear"
              routerLink={`/books`}
              routerDirection="back"
            >
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Book Summary</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          {report.map((item, idx) => (
            <IonItem key={idx}>
              <h6
                style={{ margin: 0, marginRight: 15 }}
              >{`${item.label}: `}</h6>
              <span>{item.summary}</span>
            </IonItem>
          ))}
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
