import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";

import "./VaccineCard.css";

import { Vaccine } from "../../../models";
import useVaccinesService from "../../../services/useVaccinesService";
import useCalvesService from "../../../services/useCalvesService";

interface VaccineCardProps {
  vaccine: Vaccine;
  vaccineUrl: string;
}

export default function VaccineCard({
  vaccine,
  vaccineUrl,
}: VaccineCardProps): React.ReactElement {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { deleteVaccine } = useVaccinesService();
  const { getLatestVaccineDate } = useCalvesService();
  const url = vaccine.isBatch ? `/batches/${vaccine.batchId}` : vaccineUrl;

  const onDeleteVaccine = () => {
    // if (vaccine.calfId) {
    //   getLatestVaccineDate(vaccine.calfId, vaccine.id);
    // }
    // deleteVaccine(vaccine.id);
    presentAlert({
      header: "Are you sure you want to delete the vaccine?",
      buttons: [
        { text: "No", role: "cancel", handler: () => {} },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {
            if (vaccine.calfId) {
              getLatestVaccineDate(vaccine.calfId, vaccine.id);
            }
            deleteVaccine(vaccine.id);
          },
        },
      ],
    });
  };

  const onCardClicked = (e: any) => {
    if (e.target.id === "delete") {
      //return onDeleteVaccine();
      // return presentAlert({
      //   header: "Are you sure you want to delete the vaccine?",
      //   buttons: [
      //     { text: "No", role: "cancel", handler: () => {} },
      //     {
      //       text: "Yes",
      //       role: "confirm",
      //       handler: () => {
      //         onDeleteVaccine();
      //       },
      //     },
      //   ],
      // });
    } else {
      router.push(url, "forward");
    }
  };

  return (
    <IonCard onClick={(e) => onCardClicked(e)}>
      <IonCardHeader>
        <IonCardTitle>{`${vaccine.isBatch ? "(Batch)" : ""}${
          vaccine.shotName
        }`}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className={"VaccineCardContent"}>
          <IonIcon
            id="delete"
            size="small"
            color={"danger"}
            src={"assets/trash.svg"}
            onClick={onDeleteVaccine}
          />
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Dose</IonLabel>
            <IonLabel color={"primary"}>{vaccine.dose}</IonLabel>
          </div>
          <div className="VaccineCardField">
            <IonLabel color={"medium"}>Date</IonLabel>
            <IonLabel color={"primary"}>{vaccine.date}</IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
}
